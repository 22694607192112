import gsap from "gsap";

export const highlightMapSVG = () => {
  gsap.to(".map-svg", {
    stroke: "white",
    duration: 0.25,
  });
};

export const removeHighlightMapSVG = () => {
  gsap.to(".map-svg", {
    stroke: "black",
    duration: 0.25,
  });
};

export const highlightOffersSVG = () => {
  gsap.to(".offers-svg", {
    stroke: "white",
    duration: 0.25,
  });
};

export const removeHighlightOffersSVG = () => {
  gsap.to(".offers-svg", {
    stroke: "black",
    duration: 0.25,
  });
};
