import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme/customTheme";
import dynamic from "next/dynamic";
const Scene = dynamic(() => import("../components/fiber-components/Scene"));
import ICustomAppProps from "../interfaces/ICustomAppProps";
import Layout from "../components/Layout/Layout";
import "../styles/globals.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { english_lang } from "../languages/english";
import { greek_lang } from "../languages/greek";
import Script from "next/script";
import Head from "next/head";
import { useRouterStore } from "../store/useRouterStore";
import { ROOMS_DATA } from "../configs/ROOMS_DATA";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: english_lang },
    gr: { translation: greek_lang },
  },
  lng: "gr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function MyApp({ Component, pageProps }: ICustomAppProps) {
  const { boothID } = useRouterStore();
  let title = Component.title;
  if (boothID) {
    const [hall] = boothID.split("-");
    const singleBooth = ROOMS_DATA[hall]?.find?.(
      ({ name }) => name === boothID
    );
    title = singleBooth?.id
      ? `Exhibition Booths | ${singleBooth?.id}`
      : Component.title;
  }
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload" id="gtag-script">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
        `}
      </Script>
      {/* <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');
          `,
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`}
          height="0"
          width="0"
          title="gtm"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript> */}
      <Head>
        <title>{`${title || "Building Center"}`}</title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width, user-scalable=no"
        />
        <meta
          name="keywords"
          content="Αρχιτεκτονική, 3d πλατφόρμα, 3d plarform, Αρχιτεκτονικά κτίρια, Αrchitectural buildings , ιδέες Αρχιτεκτονικής, Αrchitectural concepts , Οικοδομικά υλικά, Building Materials, Interior design, Διακόσμηση εσωτερικών χώρων, Διακόσμηση Εξωτερικών χώρων, Exterior design, Δάπεδα, Flooring, Βαφές, Κόλλες, Paints, Varnishes, Μόνωση, Insulation, Sealing, Δάπεδα, Flooring, Υδραυλικά , Αποχετεύσεις, Water, Sewage, Φωτισμός, Ηλεκτρολογικά, Lighting, Electrical equipment, Θέρμανση, Ψύξη, Κλιματισμός, Heating, Air Conditioning, Όψεις, Επικαλύψεις, Στέγες, Facades, Coating, Roofs"
        ></meta>
        <meta
          name="description"
          content="Το BUILDING CENTER είναι η πρώτη 3D πλατφόρμα Αρχιτεκτονικής και Τεχνολογικής Ενημέρωσης που προσφέρει αρχιτεκτονικές ιδέες και χρήσιμες πληροφορίες."
        ></meta>
      </Head>
      <ThemeProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
          {Component?.r3f && (
            <Scene>{Component.r3f(pageProps) as typeof Component}</Scene>
          )}
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
