import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { updateHallAndBoothURL } from "../../helpers/updateBoothURL";
import {
  BoothLogosColumnWrapper,
  BoothLogosTopColumnWrapper,
  BoothLogosWrapper,
  DesktopHallDescriptionsWrapper,
  ExhibitorsMapStackWrapper,
  ExhibitorsMapTypographyDescription,
  ImageLogoWrapper,
} from "./ExhibitorsMapCustomComponents";
import { useRouterStore } from "../../store/useRouterStore";
import ExhibitorsMapLeftNav from "./ExhibitorsMapLeftNav";
import { Typography, Button, Box, Skeleton } from "@mui/material";
import Image from "next/image";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import { openStripes } from "../../animations/animateStripes";
import { useRouter } from "next/router";
import { animateShowBlackBanner } from "../../animations/animateBlackBanner";
import ExhibitorsMapFiberComponent from "../fiber-components/ExhibitorsMapFiberComponent";
import { useEffect, useState } from "react";
import getBoothsData from "../../helpers/getBoothsData";
import { IBooth } from "../../store/interfaces/IBooth";
import { Vector3 } from "three";
import LeftArrowIcon from "../GenericLayout/LeftArrowIcon";
import RightArrowIcon from "../GenericLayout/RightArrowIcon";
import { HALLS } from "../../configs/HALLS_CONFIG";
import { SimpleBox } from "../MaterialCustomComponents";
import ExhibitorsMapSkeletonImages from "./ExhibitorsMapSkeletonImages";
import { useWindowSize } from "../../hooks/useWindowSize";
import { getExpoMapData, IExpoMapData } from "../../helpers/getExpoMapData";

const ExhibitorsMap = () => {
  const {
    setIsMenuExpanded,
    setIsSideNavOpen,
    setIsBurgerOpen,
    setIsExhibitorsMapIconClicked,
    setIsExpoPageLoaded,
  } = useComponentLoadingStateStore();
  const { highlightedHall, setHighlightedHall, setBoothClickTriggeredFrom } =
    useRouterStore();
  const { pathname } = useRouter();
  const basePath = pathname.split("/")[1];
  const [roomData, setRoomData] = useState<Record<string, IBooth[]>>({});
  const [expoMapData, setExpoMapData] = useState<IExpoMapData[]>([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    getBoothsData().then((data) => {
      getExpoMapData(data[0]?.rooms).then((res) => setExpoMapData(res));
      const stands: Record<string, IBooth[]> = {};
      data[0]?.stands.forEach((stand) => {
        const [hallID, boothId]: [string, string] =
          stand.description.split("/");
        stands[hallID] ??= [];
        stands[hallID].push({
          ...stand,
          name: `${hallID}-${boothId}`,
          id: "",
          position: new Vector3(),
        });
      });
      Object.values(HALLS).forEach((hall) => {
        stands[hall].sort((name1: any, name2: any): any => {
          const splitBoothName1 = name1.name.split("-")[1].toUpperCase();
          const splitBoothName2 = name2.name.split("-")[1].toUpperCase();
          if (splitBoothName1 < splitBoothName2) {
            return -1;
          }
          if (splitBoothName1 > splitBoothName2) {
            return 1;
          }
        });
      });
      setRoomData(stands);
    });
  }, []);

  useEffect(() => {
    if (loadedImagesCount < 16) return;
    setIsImageLoaded(true);
    setLoadedImagesCount(0);
  }, [loadedImagesCount]);
  const handleImageClick = (boothName: string) => {
    if (!boothName) return;
    setIsExhibitorsMapIconClicked(true);
    if (basePath === "expo") {
      setBoothClickTriggeredFrom("expo");
      animateShowBlackBanner();
      setIsMenuExpanded(false);
      setIsSideNavOpen(false);
      setIsBurgerOpen(false);
      updateHallAndBoothURL(highlightedHall[1], boothName);
      return;
    }
    setBoothClickTriggeredFrom("global");
    openStripes(() => {
      setIsMenuExpanded(false);
      setIsSideNavOpen(false);
      setIsBurgerOpen(false);
      setIsExpoPageLoaded(false);
      updateHallAndBoothURL(highlightedHall[1], boothName);
    });
  };
  const handleArrowClick = (direction: string) => {
    const splitedHall = Number(highlightedHall.split("H")[1]);
    if (
      (direction === "left" && splitedHall <= 1) ||
      (direction === "right" && splitedHall >= 6)
    )
      return;
    if (direction === "left" && splitedHall > 1) {
      setHighlightedHall(`H${splitedHall - 1}`);
    }
    if (direction === "right" && splitedHall < 6) {
      setHighlightedHall(`H${splitedHall + 1}`);
    }
    setIsImageLoaded(false);
  };
  const hallNumber = Number(highlightedHall.split("H")[1]);
  return (
    <GenericExpandedMenuWrapper>
      <ExhibitorsMapLeftNav />
      <GenericExpandedMenuContentWrapper>
        <ExhibitorsMapStackWrapper>
          <DesktopHallDescriptionsWrapper>
            <SimpleBox>
              <Typography>Previous</Typography>
              <Button
                variant="controlPanel"
                onClick={() => handleArrowClick("left")}
              >
                <LeftArrowIcon strokeWidth={3} />
              </Button>
            </SimpleBox>
            <SimpleBox
              sx={{
                maxWidth: "500px",
              }}
            >
              {expoMapData.length > 1 ? (
                expoMapData?.map(
                  (expoMapData: IExpoMapData) =>
                    hallNumber === expoMapData.hallNumber && (
                      <Box component="div">
                        <Typography variant="h4" fontWeight="bold">
                          HALL {expoMapData.hallNumber}
                        </Typography>
                        <Typography variant="h6" fontWeight="bold">
                          {expoMapData.hallDescription}
                        </Typography>
                        <ExhibitorsMapTypographyDescription variant="body1">
                          {expoMapData.hallDetails}
                        </ExhibitorsMapTypographyDescription>
                      </Box>
                    )
                )
              ) : (
                <Skeleton
                  variant="text"
                  width="100px"
                  sx={{ fontSize: "1.2rem" }}
                />
              )}
            </SimpleBox>
            <SimpleBox>
              <Typography>Next</Typography>
              <Button
                variant="controlPanel"
                onClick={() => handleArrowClick("right")}
              >
                <RightArrowIcon strokeWidth={3} />
              </Button>
            </SimpleBox>
          </DesktopHallDescriptionsWrapper>
          <BoothLogosWrapper>
            <ExhibitorsMapSkeletonImages displaySkeleton={isImageLoaded} />
            {width <= 600 ? (
              <SimpleBox
                sx={{
                  width: "calc(100vw - 2rem)",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {roomData?.[highlightedHall]?.map((item, index) => (
                  <ImageLogoWrapper key={index}>
                    <Image
                      src={item?.mobileLogo}
                      width={120}
                      height={60}
                      priority={true}
                      alt="company logo"
                      onLoad={() => setLoadedImagesCount((state) => state + 1)}
                      onClick={() => handleImageClick(item?.name)}
                      style={{
                        cursor: "pointer",
                        opacity: !isImageLoaded ? 0 : 1,
                      }}
                    />
                  </ImageLogoWrapper>
                ))}
              </SimpleBox>
            ) : (
              <>
                <BoothLogosTopColumnWrapper>
                  <SimpleBox>
                    <ImageLogoWrapper>
                      <Image
                        src={roomData?.[highlightedHall]?.[5]?.mobileLogo}
                        width={150}
                        height={80}
                        priority={true}
                        alt="company logo"
                        onLoad={() =>
                          setLoadedImagesCount((state) => state + 1)
                        }
                        onClick={() =>
                          handleImageClick(
                            roomData?.[highlightedHall]?.[5]?.name
                          )
                        }
                        style={{
                          cursor: "pointer",
                          opacity: !isImageLoaded ? 0 : 1,
                        }}
                      />
                    </ImageLogoWrapper>
                  </SimpleBox>
                  <SimpleBox>
                    <ImageLogoWrapper>
                      <Image
                        src={roomData?.[highlightedHall]?.[15]?.mobileLogo}
                        width={150}
                        height={80}
                        priority={true}
                        alt="company logo"
                        onLoad={() =>
                          setLoadedImagesCount((state) => state + 1)
                        }
                        onClick={() =>
                          handleImageClick(
                            roomData?.[highlightedHall]?.[15]?.name
                          )
                        }
                        style={{
                          cursor: "pointer",
                          opacity: !isImageLoaded ? 0 : 1,
                        }}
                      />
                    </ImageLogoWrapper>
                  </SimpleBox>
                </BoothLogosTopColumnWrapper>
                <BoothLogosColumnWrapper>
                  {roomData?.[highlightedHall]
                    ?.slice(0, 5)
                    .reverse()
                    .map((booth: Record<string, unknown>, index: number) => {
                      return (
                        <ImageLogoWrapper key={index}>
                          <Image
                            src={String(booth.mobileLogo)}
                            width={150}
                            height={80}
                            priority={true}
                            alt="company logo"
                            onLoad={() =>
                              setLoadedImagesCount((state) => state + 1)
                            }
                            onClick={() => handleImageClick(String(booth.name))}
                            style={{
                              cursor: "pointer",
                              opacity: !isImageLoaded ? 0 : 1,
                            }}
                          />
                        </ImageLogoWrapper>
                      );
                    })}
                </BoothLogosColumnWrapper>
                <BoothLogosColumnWrapper>
                  {roomData?.[highlightedHall]
                    ?.slice(6, 10)
                    .reverse()
                    .map((booth: Record<string, unknown>, index: number) => {
                      return (
                        <ImageLogoWrapper key={index}>
                          <Image
                            src={String(booth.mobileLogo)}
                            width={150}
                            height={80}
                            priority={true}
                            alt="company logo"
                            onLoad={() =>
                              setLoadedImagesCount((state) => state + 1)
                            }
                            onClick={() => handleImageClick(String(booth.name))}
                            style={{
                              cursor: "pointer",
                              opacity: !isImageLoaded ? 0 : 1,
                            }}
                          />
                        </ImageLogoWrapper>
                      );
                    })}
                </BoothLogosColumnWrapper>
                <BoothLogosColumnWrapper>
                  {roomData?.[highlightedHall]
                    ?.slice(10, 15)
                    .reverse()
                    .map((booth: Record<string, unknown>, index: number) => {
                      return (
                        <ImageLogoWrapper key={index}>
                          <Image
                            src={String(booth.mobileLogo)}
                            width={150}
                            height={80}
                            priority={true}
                            alt="company logo"
                            onLoad={() =>
                              setLoadedImagesCount((state) => state + 1)
                            }
                            onClick={() => handleImageClick(String(booth.name))}
                            style={{
                              cursor: "pointer",
                              opacity: !isImageLoaded ? 0 : 1,
                            }}
                          />
                        </ImageLogoWrapper>
                      );
                    })}
                </BoothLogosColumnWrapper>
              </>
            )}
          </BoothLogosWrapper>
        </ExhibitorsMapStackWrapper>
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

ExhibitorsMap.r3f = () => {
  return <ExhibitorsMapFiberComponent />;
};

export default ExhibitorsMap;
