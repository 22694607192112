import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    back: true;
    controlPanel: true;
    controlPanelDesktop: true;
    burger: true;
    conference: true;
    conferencePrimary: true;
    conferenceSecondary: true;
    singleVideoItem: true;
  }
}

declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    white: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ["Open-sans", "sans-serif"].join(","),
    fontWeight: {
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    display: {
      fontSize: "61px",
    },
    h1: {
      fontSize: "49px",
    },
    h2: {
      fontSize: "39px",
    },
    h3: {
      fontSize: "31px",
    },
    h4: {
      fontSize: "25px",
    },
    h5: {
      fontSize: "20px",
    },
    body: {
      fontSize: "16px",
    },
    small: {
      fontSize: "13px",
    },
    xsmall: {
      fontSize: "10px",
    },
  },
  palette: {
    primaryColor: {
      main: "#000000",
      shade1: "#3D3D3D",
      shade2: "#525252",
      shade3: "#666666",
      shade4: "#7A7A7A",
      shade5: "#8F8F8F",
      shade6: "#A3A3A3",
      shade7: "#B8B8B8",
      shade8: "#CCCCCC",
      shade9: "#E0E0E0",
      shade10: "#F5F5F5",
    },
    secondaryColor: {
      main: "#E81C00",
      shade1: "#6E0D00",
      shade2: "#961200",
      shade3: "#BF1700",
      shade4: "#E81C00",
      shade5: "#FF3215",
      shade6: "#FF563F",
      shade7: "#FF7B69",
      shade8: "#FFA093",
      shade9: "#FFC5BD",
      shade10: "#FFEAE7",
    },
    white: {
      main: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            color: "white",
            borderRadius: 0,
            backgroundColor: "black",
            fontWeight: 600,
            fontSize: "20px",
            minWidth: "178px",
            "&:hover": {
              backgroundColor: "#E81C00",
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            color: "white",
            borderRadius: 0,
            backgroundColor: "#E81C00",
            fontSize: "20px",
            fontWeight: 600,
            minWidth: "178px",
            "&:hover": {
              backgroundColor: "black",
            },
          },
        },
        {
          props: { variant: "back" },
          style: {
            color: "white",
            borderRadius: 0,
            opacity: 0.9,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            fontWeight: 600,
            minWidth: "178px",
            display: "flex",
            gap: "1rem",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 1)",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            userSelect: "none",
            color: "black",
            borderRadius: 0,
            fontWeight: 600,
            fontSize: "20px",
            minWidth: "178px",
            borderColor: "black",
            "&:hover": {
              borderColor: "black",
              backgroundColor: "black",
              color: "white",
            },
          },
        },
        {
          props: { variant: "controlPanel" },
          style: {
            color: "black",
            userSelect: "none",
            borderRadius: 0,
            backgroundColor: "black",
            cursor: "pointer",
            fontSize: "20px",
            width: "50px",
            height: "64px",
            pointerEvents: "auto",
            "@media (pointer: coarse)": {
              "&:hover": {
                backgroundColor: "black !important",
              },
            },
            "&:hover": {
              backgroundColor: "red",
            },
            "&.Mui-disabled": {
              opacity: 0,
            },
          },
        },
        {
          props: { variant: "controlPanelDesktop" },
          style: {
            color: "black",
            borderRadius: 0,
            backgroundColor: "black",
            cursor: "pointer",
            fontSize: "20px",
            width: "135px",
            height: "64px",
            display: "flex",
            flexDirection: "column",
            pointerEvents: "auto",
            "@media (pointer: coarse)": {
              "&:hover": {
                backgroundColor: "black !important",
              },
            },
            "&:hover": {
              backgroundColor: "red",
            },
            "&.Mui-disabled": {
              opacity: 0.5,
            },
          },
        },
        {
          props: { variant: "burger" },
          style: {
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: "10",
            cursor: "pointer",
            height: "50px",
            width: "50px",
            margin: "1rem",
            pointerEvents: "auto",
            borderRadius: 0,
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "red",
            },
            "@media (pointer: coarse)": {
              "&:hover": {
                backgroundColor: "black !important",
              },
            },
          },
        },
        {
          props: { variant: "conference" },
          style: {
            color: "white",
            borderRadius: 0,
            backgroundColor: "black",
            cursor: "pointer",
            fontSize: "20px",
            pointerEvents: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "column",
            gap: "5px",
            height: "88px",
            width: "79px",
            "&:hover": {
              backgroundColor: "red",
            },
            "@media (pointer: coarse)": {
              "&:hover": {
                backgroundColor: "black !important",
              },
            },
          },
        },
        {
          props: { variant: "conferencePrimary" },
          style: {
            color: "white",
            borderRadius: 0,
            backgroundColor: "red",
            cursor: "pointer",
            fontSize: "20px",
            pointerEvents: "auto",
            height: "40px",
            width: "160px",
            "&:hover": {
              backgroundColor: "black",
            },
            "@media (pointer: coarse)": {
              "&:hover": {
                backgroundColor: "red !important",
              },
            },
          },
        },
        {
          props: { variant: "conferenceSecondary" },
          style: {
            color: "white",
            borderRadius: 0,
            backgroundColor: "black",
            cursor: "pointer",
            fontSize: "20px",
            pointerEvents: "auto",
            height: "40px",
            width: "160px",
            "&:hover": {
              backgroundColor: "red",
            },
            "@media (pointer: coarse)": {
              "&:hover": {
                backgroundColor: "black !important",
              },
            },
          },
        },
        {
          props: { variant: "singleVideoItem" },
          style: {
            color: "error",
            width: "100%",
            display: "flex",
            justifyContent: "start",
            borderRadius: 0,
            "&:hover": {
              backgroundColor: "rgba(232, 28, 0, 0.6)",
            },
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(225, 225, 225, 0.98)",
          overflowY: "auto",
          borderRadius: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          borderBottom: "1px solid white",
          "&.Mui-selected": {
            color: "red",
            fontWeight: "bold",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: "transparent",
          color: "white",
          borderBottom: "1px solid white",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "#EC2437",
        },
      },
    },
    MuiLinearProgress: {
      variants: [
        {
          props: { variant: "indeterminate", color: "white" },
          style: {
            color: "#ffffff",
            width: "200px",
            backgroundColor: "#000000",
          },
        },
      ],
    },
  },
});
