import gsap from "gsap";
import {
  EXPANDED_STRIPE_WIDTH,
  EXPAND_STRIPE_DURATION,
  RETRACK_STRIPE_DURATION,
  RETRACTED_STRIPE_WIDTH,
} from "../configs/STRIPES_ANIMATION_CONFIG";
import { useComponentLoadingStateStore } from "../store/useComponentLoadingStateStore";
import { useRouterStore } from "../store/useRouterStore";

export const openStripes = (cb: any) => {
  useComponentLoadingStateStore.setState({
    isMenuExpanded: false,
    isSideNavOpen: false,
    isBurgerOpen: false,
  });
  const tl = gsap.timeline({
    onComplete: () => {
      cb();
    },
  });

  tl.to(
    ".stripe-1",
    {
      width: EXPANDED_STRIPE_WIDTH,
      duration: EXPAND_STRIPE_DURATION,
    },
    0.1
  )
    .to(
      ".stripe-2",
      {
        width: EXPANDED_STRIPE_WIDTH,
        duration: EXPAND_STRIPE_DURATION,
      },
      0.2
    )
    .to(
      ".stripe-3",
      {
        width: EXPANDED_STRIPE_WIDTH,
        duration: EXPAND_STRIPE_DURATION,
      },
      0.3
    )
    .to(
      ".stripe-4",
      {
        width: EXPANDED_STRIPE_WIDTH,
        duration: EXPAND_STRIPE_DURATION,
      },
      0.4
    );
};

export const closeStripes = (cb?: any) => {
  const tl = gsap.timeline({
    onComplete: () => {
      useComponentLoadingStateStore.setState({
        isExpoPageLoaded: false,
        isConferenceHallPageLoaded: false,
        isReceptionPageLoaded: false,
      });
      useRouterStore.setState({ boothClickTriggeredFrom: "expo" });
      if (!cb) return;
      cb();
    },
  });
  tl.to(
    ".stripe-1",
    {
      width: RETRACTED_STRIPE_WIDTH,
      duration: RETRACK_STRIPE_DURATION,
      delay: 0.3,
    },
    0.4
  )
    .to(
      ".stripe-2",
      {
        width: RETRACTED_STRIPE_WIDTH,
        duration: RETRACK_STRIPE_DURATION,
      },
      0.5
    )
    .to(
      ".stripe-3",
      {
        width: RETRACTED_STRIPE_WIDTH,
        duration: RETRACK_STRIPE_DURATION,
      },
      0.6
    )
    .to(
      ".stripe-4",
      {
        width: RETRACTED_STRIPE_WIDTH,
        duration: RETRACK_STRIPE_DURATION,
      },
      0.7
    );
};
