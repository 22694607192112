import { styled, Box, Typography, Dialog } from "@mui/material";

export const SimpleBox: any = styled(Box)``;

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-row: 4 / span 5;
  grid-column: 1 / span 9;
  color: white;
  background: rgba(0, 0, 0, 0.3);
`;

export const ResponsiveTypographyHeader = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
    lineHeight: "1.8rem",
  },
}));
export const ResponsiveTypographySubheader = styled(Typography)(
  ({ theme }) => ({
    fontSize: "31px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "1.5rem",
    },
  })
);
export const ResponsiveTypographyBody = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

export const ResponsiveTypographyButton = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
    lineHeight: "1.5rem",
  },
}));

export const ReceptionBgVideoOuterWrapper = styled(Box)(() => ({
  position: "absolute",
  width: "100vw",
  height: "100vh",
  zIndex: 3,
}));

export const ReceptionBgVideoInnerWrapper = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  zIndex: 4,
  backgroundColor: "rgba(0, 0, 0, 0.4)",
}));

export const BlackBgBanner = styled(Box)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0;
`;

export const IconWrapper = styled(Box)`
  cursor: pointer;
`;

export const BurgerWrapper = styled(Box)(({}) => ({
  position: "absolute",
  top: "0",
  right: "0",
  zIndex: "10",
  cursor: "pointer",
  pointerEvents: "auto",
}));

export const Menu = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f2f2f2;
  height: 0vh;
  width: 18rem;
  margin: 1rem;
  font-size: 1rem;
  padding: 48px;
  z-index: -1;
  opacity: 0;
`;

export const SafariDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-container": {
    alignItems: "start",
    paddingTop: "2rem",
  },
  ".MuiDialog-paper": {
    backgroundColor: "#fff",
    overflowX: "hidden",
    padding: "0.4rem",
    [theme.breakpoints.down("md")]: {
      margin: "0rem",
    },
  },
}));
