import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { CircularProgress, Typography } from "@mui/material";
import { SimpleBox } from "../MaterialCustomComponents";

const LoadingSVG = () => {
  const { loaderPercentage } = useComponentLoadingStateStore();

  return (
    <SimpleBox sx={{ position: "relative" }}>
      <CircularProgress value={loaderPercentage} size="3.5rem" />
      <Typography
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {`${Math.round(loaderPercentage)}%`}
      </Typography>
    </SimpleBox>
  );
};

export default LoadingSVG;
