import {
  getActiveRoom,
  getConferenceHall,
  getReception,
  getRooms,
  getStands,
} from "./getData";

const getBoothsData = async () => {
  const activeRoom = await getActiveRoom();

  const conferenceHall = await getConferenceHall(activeRoom);

  const rooms = await getRooms(activeRoom);

  const reception = await getReception(activeRoom);

  const stands = await getStands(activeRoom);

  return [
    {
      stands,
      conferenceHall,
      rooms,
      reception,
    },
  ];
};
export default getBoothsData;
