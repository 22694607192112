import Offers from "../components/OffersPage/Offers";
import Newsletter from "../components/Newsletter/Newsletter";
import About from "../components/About/About";
import Exhibitors from "../components/Exhibitors/Exhibitors";
import Visitors from "../components/Visitors/Visitors";
import Terms from "../components/Contact/Contact";
import Contact from "../components/Contact/Contact";
import Reception from "../components/Reception/Reception";
import ExhibitorsMap from "../components/Exhibitors-map/ExhibitorsMap";
import { INavigationPages } from "./interfaces/INavigationPages";

export const NAVIGATION_PAGES: INavigationPages = {
  reception: <Reception />,
  exhibitorsMap: <ExhibitorsMap />,
  about: <About />,
  exhibitors: <Exhibitors />,
  visitors: <Visitors />,
  contact: <Contact />,
  newsletter: <Newsletter />,
  offers: <Offers />,
};

export const RECEPTION = "reception";
export const EXHIBITION_HALL = "exhibitionHall";
export const CONFERENCE_HALL = "conferenceHall";
export const EXHIBITORS_MAP = "exhibitorsMap";
export const ABOUT = "about";
export const EXHIBITORS = "exhibitors";
export const VISITORS = "visitors";
export const CONTACT = "contact";
export const NEWSLETTER = "newsletter";
export const OFFERS = "offers";
export const HIGHLIGHT_COLOR = "#EC2437";
export const BLACK = "black";

export const H1 = "H1";
export const H2 = "H2";
export const H3 = "H3";
export const H4 = "H4";
export const H5 = "H5";
export const H6 = "H6";
