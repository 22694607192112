import { Box, Stack, styled, Typography } from "@mui/material";
import { HIGHLIGHT_COLOR } from "../../configs/NAVIGATION_PAGES";
export const ExhibitorsMapGridWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "20rem 1fr",
  height: "100vh",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

export const ExhibitorsMapStackWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
  },
}));

export const DesktopHallDescriptionsWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "2rem",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    alignItems: "start",
    gap: "1rem",
  },
}));

export const ExhibitorsMapLeftNavWrapper = styled(Box)(({ theme }) => ({
  borderRight: "1px solid lightgrey",
  padding: "1rem",
  [theme.breakpoints.up(1200)]: {
    height: "100vh",
    overflow: "auto",
  },
}));
`

`;

export const HallSelectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "6rem",
  padding: "0rem 1rem",
  width: "calc(100vw - 2rem)",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

export const SelectedHallWrapper = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  paddingTop: "8px",
  paddingBottom: "8px",
  "&:hover": {
    color: HIGHLIGHT_COLOR,
  },
  [theme.breakpoints.down("md")]: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
}));

export const HallsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100% - 5rem - 83px)",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const HallsInnerWrapper = styled(Box)`
  padding: 3rem 0rem;
`;

export const HallsInnerMobileWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const HallSelectionTopDescription = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const ExhibitorsMapLeftNavFooter = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const BoothLogosWrapper = styled(Box)(({}) => ({
  position: "relative",
  maxWidth: "600px",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  height: "calc(100vh - 15rem)",
  alignContent: "start",
}));

export const BoothLogosTopColumnWrapper = styled(Box)(({}) => ({
  gridRow: 1,
  gridColumn: "1 / span 12",
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const BoothLogosColumnWrapper = styled(Box)(({}) => ({
  gridRow: 2,
}));

export const BoothLogosInnerWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  gridColumn: "1 / span 12",
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "600px",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  justifySelf: "center",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    paddingBottom: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    gridColumn: "1 / span 12",
  },
}));

export const ExhibitorsMapLeftNavTopWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const ChooseHallTypography = styled(Typography)(({ theme }) => ({
  padding: "1rem 0rem",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const ExhibitorsMapTypographyDescription = styled(Typography)(
  ({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  })
);

export const SelectedHallTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1.8rem",
  },
}));

export const ImageLogoOuterWrapper = styled(Box)(({}) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "24px",
  justifyContent: "center",
  alignItems: "center",
}));

export const ImageLogoWrapper = styled(Box)`
  margin: 1rem;
  img {
    box-shadow: 0px 2px 16px 1px #80808040;
  }
  img:hover {
    box-shadow: 0px 2px 5px 1px gray;
  }
`;
