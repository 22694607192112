import { collection, query, getDocs, addDoc, where } from "firebase/firestore";
import { database } from "../firebase/connectFirebase";

export const getActiveRoom = async () =>
  (await getDocs(query(collection(database, "appSettings")))).docs.map((doc) =>
    doc.data()
  )[0].active;

export const getConferenceHall = async (activeRoom: string) =>
  (
    await getDocs(collection(database, "expos", activeRoom, "conferenceHall"))
  ).docs.map((doc) => doc.data());

export const getLiveVideos = async (activeRoom: string) =>
  (await getDocs(collection(database, "expos", activeRoom, "videos"))).docs.map(
    (doc) => doc.data()
  );

export const getRooms = async (activeRoom: string) =>
  (await getDocs(collection(database, "expos", activeRoom, "rooms"))).docs.map(
    (doc) => doc.data()
  );

export const getReception = async (activeRoom: string) =>
  (
    await getDocs(collection(database, "expos", activeRoom, "reception"))
  ).docs.map((doc) => doc.data());

export const getStands = async (activeRoom: string) =>
  (await getDocs(collection(database, "expos", activeRoom, "stands"))).docs.map(
    (doc) => doc.data()
  );

export const getVideoViewsData = async (
  expoId: string,
  videoId: string,
  user_id: string
) => {
  const expoDoc = collection(
    database,
    `expos/${expoId}/videos/${videoId}/views`
  );
  const x = (
    await getDocs(query(expoDoc, where("id", "==", user_id)))
  ).docs.map((doc) => doc.data());
  return x;
};

export const createVideoViewsData = async (
  expoId: string,
  videoId: string,
  id: string
) => {
  const expoDoc = collection(
    database,
    `expos/${expoId}/videos/${videoId}/views`
  );
  const x = await addDoc(expoDoc, { id });
  return x;
};
