import gsap from "gsap";

export const animateScrolldownIcon = (element: HTMLElement) => {
  const tl = gsap.timeline({ repeat: -1 });
  tl.to(element, {
    y: 20,
    duration: 1,
    delay: 0.2,
  }).to(element, {
    y: 0,
    duration: 1,
    delay: 0.2,
  });
};
