import { RightPanelWrapper } from "../GenericLayout/RightPanelWrapper";
import { SideNavWrapper } from "./NavigationCustomComponents";
import NavigationSideNav from "./NavigationSideNav";
import { Paper, Slide } from "@mui/material";
import { ExpandedNavWrapper } from "./ExpandedNavigationComponents";
import ExpandedNavigation from "./ExpandedNavigation";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import MenuSVG from "../GenericLayout/MenuSVG";
import { closeAllNavMenus } from "../../helpers/closeAllNavMenus";
import { BurgerWrapper } from "../MaterialCustomComponents";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useWindowSize } from "../../hooks/useWindowSize";

const Menu = () => {
  const {
    setIsMenuExpandedMounted,
    isMenuExpanded,
    isSideNavOpen,
    setIsSideNavOpen,
    setIsMenuExpanded,
  } = useComponentLoadingStateStore();

  const [isConferenceMobile, setIsConferenceMobile] = useState(false);
  const { width, height } = useWindowSize();
  const { route } = useRouter();

  const stickBurgerMenu = () => {
    if (!width || !route) return;
    if (route === "/conference_hall" && width < 901) {
      setIsConferenceMobile(true);
      return;
    }
    setIsConferenceMobile(false);
  };

  useEffect(() => {
    if (!route || !width) return;
    stickBurgerMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, width]);

  const handleBurgerClick = () => {
    if (isSideNavOpen && width >= 900) {
      closeAllNavMenus();
      return;
    }
    if (isSideNavOpen && !isMenuExpanded && width <= 900) {
      setIsSideNavOpen(false);
      return;
    }
    if (isSideNavOpen && isMenuExpanded && width <= 900) {
      setIsMenuExpanded(false);
      return;
    }
    setIsSideNavOpen(true);
  };

  return (
    <>
      <RightPanelWrapper>
        <BurgerWrapper
          className="burger-div"
          onClick={() => handleBurgerClick()}
          sx={{
            margin: isConferenceMobile ? "0px" : "24px",
          }}
        >
          <MenuSVG />
        </BurgerWrapper>

        <SideNavWrapper>
          <Slide direction="left" in={isSideNavOpen} mountOnEnter unmountOnExit>
            <Paper sx={{ height: height }}>
              <NavigationSideNav />
            </Paper>
          </Slide>
        </SideNavWrapper>
      </RightPanelWrapper>
      <ExpandedNavWrapper>
        <Slide
          direction="left"
          in={isMenuExpanded}
          timeout={400}
          mountOnEnter
          unmountOnExit
          onExited={() => setIsMenuExpandedMounted(false)}
        >
          <Paper sx={{ height: height }}>
            <ExpandedNavigation />
          </Paper>
        </Slide>
      </ExpandedNavWrapper>
    </>
  );
};

export default Menu;
