import { styled, Button, Box } from "@mui/material";

export const ReceptionBg = styled(Box)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: url("/reception-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  opacity: 0;
`;

export const ReceptionWrapper = styled(Box)`
  width: 100vw;
  position: absolute;
  top: 0;
  overflow-x: hidden;
  margin-top: 24px;
  padding: 0px 24px 0px 24px;
  opacity: 0;
`;

export const ReceptionInnerWrapper = styled(Box)`
  margin: auto;
  text-align: center;
  padding: 0rem 0.7rem;
`;

export const ReceptionContentPlaceholder = styled(Box)`
  height: 100vh;
`;

export const ReceptionOverlay = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  width: "50%",
  margin: "auto",
  maxWidth: "730px",
  [theme.breakpoints.down("xl")]: {
    width: "60%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const RightProgressBar = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  height: 46.38px;
  width: 3px;
  z-index: 1;
  background-color: red;
`;

export const ReceptionTopAlert = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: "0",
  zIndex: "1",
  backgroundColor: "red",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  textAlign: "center",
  padding: "0.7rem 3rem",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    padding: "1.5rem 0.7rem 0.7rem 0.7rem",
    alignItems: "start",
    textAlign: "left",
  },
  "&:hover": {
    backgroundColor: "#c20704",
    transition: "all 250ms ease-in-out",
  },
}));

export const ReceptionLogoWrapper = styled(Box)(({ theme }) => ({
  margin: "2rem 0rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    margin: "1rem 0rem 0rem 0rem",
  },
}));

export const ReceptionImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "300px",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
}));

export const ScrollDownIconWrapper = styled(Box)(({ theme }) => ({
  margin: "5rem 0rem",
  [theme.breakpoints.down("sm")]: {
    margin: "3rem 0rem",
  },
}));

export const ReceptionButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "2rem",
  justifyContent: "center",
  margin: "2rem 0rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const ReceptionButton = styled(Button)(({ theme }) => ({
  maxWidth: "270px",
  width: "270px",
  height: "64px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

export const ReceptionTextUnderline = styled(Box)(({ theme }) => ({
  border: "2px solid #e81c00",
  width: "180px",
  marginTop: "-0.8rem",
  [theme.breakpoints.down("md")]: {
    width: "120px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90px",
  },
}));

export const ReceptionConferenceHallSectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
`;

export const FollowUsSectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  margin-top: 7rem;
`;

export const FollowUsSectionButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "2rem",
  marginBottom: "2rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const ReceptionStandSectionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;
