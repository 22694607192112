import { styled, Box } from "@mui/material";

export const StripesWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Stripe = styled(Box)`
  background-color: rgba(225, 225, 225, 1);
  width: 0vw;
  height: 25vh;
`;

export const StripeLogoWrapper = styled(Box)`
  background-color: rgba(225, 225, 225, 1);
  height: 25vh;
  width: 0vw;
  overflow: hidden;
`;

export const StripeLogoInnerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;
