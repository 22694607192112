import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const ErrorBoxStyled = styled(Typography)`
  color: red;
  padding: 20px;
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 2px;
  margin: 10px 0px;
`;
export const ErrorBox = ({ msg }: { msg: string }) => {
  return <ErrorBoxStyled>{msg}</ErrorBoxStyled>;
};
