import { Typography } from "@mui/material";
import { t } from "i18next";
import { SimpleBox } from "../MaterialCustomComponents";

const ExhibitorsMapLeftNavBottomSection = () => {
  return (
    <SimpleBox>
      <SimpleBox>
        <Typography variant="subtitle1" fontWeight="bold">
          {t("generic.more_info")}
        </Typography>
        <Typography variant="body1">
          {t("generic.contact_info_telephone")} +30 2310 480540
        </Typography>
        <Typography variant="body1">email: info@buildingcenter.gr</Typography>
      </SimpleBox>
    </SimpleBox>
  );
};

export default ExhibitorsMapLeftNavBottomSection;
