import ExhibitorsMapLeftNavTopSection from "./ExhibitorsMapLeftNavTopSection";
import ExhibitorsMapLeftNavMiddleSection from "./ExhibitorsMapLeftNavMiddleSection";
import { ExhibitorsMapLeftNavWrapper } from "./ExhibitorsMapCustomComponents";

const ExhibitorsMapLeftNav = () => {
  return (
    <ExhibitorsMapLeftNavWrapper>
      <ExhibitorsMapLeftNavTopSection />
      <ExhibitorsMapLeftNavMiddleSection />
    </ExhibitorsMapLeftNavWrapper>
  );
};

export default ExhibitorsMapLeftNav;
