import { Typography } from "@mui/material";
import Results from "./Results";
import GenericLeftNav from "../GenericLayout/GenericLeftNav";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import { useTranslation } from "react-i18next";

const Offers = () => {
  const { t } = useTranslation();
  return (
    <GenericExpandedMenuWrapper>
      <GenericLeftNav />
      <GenericExpandedMenuContentWrapper>
        <Typography variant="h4" fontWeight="bold">
          {t("offers.main_header")}
        </Typography>
        <Typography variant="subtitle2">
          {t("offers.main_subheader")}
        </Typography>
        <Results />
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

export default Offers;
