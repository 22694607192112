export const english_lang = {
  reception: {
    top_alert: {
      main_header_title: "<span>NEXT WEBINAR:</span>",
      main_header_title_live: "<span>LIVE NOW:</span>",
      main_header: "{{nextWebinar}}",
      register_button: "Register Now",
      watch_now_button: "Watch now",
    },
    main_section: {
      main_header:
        "Discover material application, technical information & architectural ideas in videos",
      buttons: {
        hall_button: "HALLS",
        conference_button: "CONFERENCE HALL",
        map_button: "MAP",
        offers_button: "OFFERS",
      },
      main_subheader: "In Building Center you will find",
    },
    stands_section: {
      main_stand_header: "Stands",
      main_stand_description:
        "Product presentation & application, technical brochures & catalogues and Architectural presentations",
      main_stand_button: "HALLS",
    },
    conference_section: {
      main_conference_header: "Conference Hall",
      main_conference_description:
        "Webinars, product application, Architectural presentations, Ktirio technologies",
      main_conference_button: "CONFERENCE HALL",
    },
    follow_section: {
      main_follow_header: "Follow Us",
      main_follow_description_department: "Product & Technology promotion Dpt.",
      main_follow_description_phone: "Tel: +30 2310 480540",
      main_follow_description_email: "Email: info@buildingcenter.gr",
      main_follow_contact_button: "CONTACT US",
      main_follow_newsletter_button: "JOIN OUR NEWSLETTER",
    },
  },
  expo: {},
  conference: {
    no_live: "There is no live event currently running",
  },
  exhibitors_map: {
    main_top_header: "EXHIBITORS MAP",
    main_header: "Choose Hall",
  },
  about: {
    about_main_header: "ABOUT US",
    about_main_subheader: "Read about Building Center",
    about_first_paragraph:
      "<strong>The online BUILDING CENTER is the first 3D Architectural and Technological Information base in Greece that offers architectural ideas and useful technical information</strong>, while at the same time facilitating its participants and visitors to come into meaningful communication with each other. It is aimed at a crowded Greek and international environment and operates annually in 3 periods with a continuous duration of 3 - 4 months each.",
    about_second_paragraph:
      "<strong>It is the continuation of a pioneering idea of ​​the KTIRIO company</strong>, implemented in 1996, in a special conference area for the presentation of products and architectural technology, which today is evolving into a modern online application.",
    about_third_paragraph:
      "At the same time, the BUILDING CENTER enhances the efforts of companies and professionals in the construction industry to disseminate their products and services, as well as to present with videos their correct application to architects and other engineers, construction companies, decorators and material applicators , investors, material traders, hoteliers, but also interested individuals throughout Greece and the international environment.",
  },
  exhibitors: {
    main_header: "EXHIBITORS",
    main_subheader: "Read about the Exhibitors of Building Center",
    main_first_paragraph:
      "The online <strong>BUILDING CENTER</strong> is the first 3D Architectural and Technological Information base in Greece and <strong>offers participating companies and professionals easy and meaningful communication with clients in Greece and abroad.</strong>",
    main_second_paragraph:
      "In this innovative project, all companies that have products and services for the construction of buildings or the renovation and design of spaces can participate and wish to directly address and inform their potential customers (architects, engineers, construction companies, decorators, investors, material traders, hoteliers, individuals, etc.).",
    main_third_paragraph:
      "The online BUILDING CENTER operates annually in 3 periods with a continuous duration of 3-4 months each and <strong>offers its visitors useful technical information.</strong>",
    list_main_header: "Each participating company has a STAND, in which:",
    list_items:
      "<li>presents its goals and capabilities with a video,</li><li>presents with video the services, and the correct application of its materials,</li><li>presents and distributes its prospectuses with information on materials and their applications,</li><li> and can directly contact interested customers.</li>",
    footer_section_header:
      "The online BUILDING CENTER operates annually in 3 periods with a continuous duration of 3-4 months each.",
    footer_section_subheader: "Learn how to join here: «pdf symbol»",
  },
  visitors: {
    main_header: "VISITORS",
    main_subheader: "Are you a visitor? Read about us",
    first_paragraph:
      "The online <strong>BUILDING CENTER</strong> enables visitors to browse and discover on the STANDS and in the CONFERENCE HALL companies with useful materials, construction solutions and quality buildings, where everything is presented through video.",
    second_paragraph:
      "At the same time, STANDS <strong>can invite and communicate directly with the participating companies and professionals</strong> (materials companies, architectural and technical offices and building construction companies).",
    third_paragraph:
      "Especially in the CONFERENCE HALL they can see more videos with construction solutions, presentations of projects by architects and technical companies, seminars, but also many quality buildings, architectural ideas, useful technical solutions, technical articles from the BUILDING.",
    fourth_paragraph:
      "The online BUILDING CENTER is the first online 3D Architectural and Technical Information platform in Greece. It enables its visitors to get in touch with companies and professionals in order to discover new building materials and modern construction ideas, as well as to acquire useful information for the construction of quality buildings. It is in fact the continuation of a pioneering idea of ​​KTIRIO magazine, which 10 years after its first edition in 1986, had created a special area for the presentation of products and architectural technology.",
    fifth_paragraph:
      "At the same time, the BUILDING CENTER aims to strengthen the efforts of companies and professionals in the construction industry, to spread their products and services to a wider and larger audience (architects, engineers, construction companies, decorators, investors, material traders, hoteliers, interested private individuals etc.).",
    footer_section_header: "In Building Center you will find",
    footer_section_categories:
      "<li><strong>Product presentations</strong></li><li><strong> Technical brochures and catalogues</strong></li><li><strong> Architectural presentations</strong></li>",
  },
  contact: {
    main_header: "CONTACT",
    main_subheader: "Contact us for more information’s",
    main_categories:
      "<li>Department of promotion of materials and partnerships</li><li>Tel: +30 2310 480540</li><li>Email: info@buildingcenter.gr</li>",
    main_button: "CONTACT FORM",
  },
  contactForm: {
    title: "CONTACT US",
    subtitle:
      "Leave your contact details and message, and we will get in touch!",
    success_message: "You message has been sent successfully",
    error_message: "There was an error sending your message",
    close: "close",
    send: "send",
  },
  newsletter: {
    main_header: "NEWSLETTER",
    main_subheader:
      "You can Subscribe to our newsletter and receive relevant updates",
    main_input_header: "Subscribe to our Newsletter",
    main_input_placeholder: "Type your email",
    main_button_label: "I'm not a robot",
    main_button: "CONFIRM",
    success_message: "You have registered our newsletter successfully",
    error_message: "There was an error subscribing to the newsletter",
  },
  offers: {
    main_header: "COMPANIES' OFFERS",
    main_subheader: "Discover all the offers that you can find in the halls.",
    no_results_title: "No results found",
    no_results_subtitle:
      "Please visit again in the future to make sure you won't miss any of our exhibitors special offers",
  },
  generic: {
    more_info: "For more information:",
    contact_info_telephone: "tel:",
  },
  terms: {
    main_header: "TERMS OF USE AND LIABILITY",
    mb1: "This Website, www.buildingcenter.gr, is the property of the company KTIRIO EDITIONS PC and is protected by the provisions of Law 2121/93 on intellectual property, as applicable. The visitor or user of the web pages and services of our website ( www.buildingcenter.gr ) must carefully read the terms of use and the terms of service that follow before visiting or using our pages and services and in case of disagreement he must not use them. In any other case, it is presumed that he accepts them and gives his consent. The following terms of use apply to all content and to what is generally included in the pages of the website www.buildingcenter.gr. The company KTIRIO EDITIONS PC (hereinafter referred to as the Beneficiary) may modify the terms and conditions of use at any time, and users - visitors must always check for possible changes and if they continue to use it is considered that they accept any modified terms and conditions. Otherwise they must abstain from using - visit www.buildingcenter.gr.",
    mh2: "Intellectual and industrial property rights",
    mb2: `All content of www.buildingcenter.gr, including, but not limited to, texts, news, graphics, photographs, blueprints, illustrations, services provided and generally any kind of archives, is the subject of copyright and is governed by national and international provisions on Intellectual Property, with the exception of explicitly recognized rights of third parties. Therefore, reproduction, republishing, copying, storage, sale, transmission, distribution, publication, execution, "download", translation, modification in any way, in part or in summary without the express written consent of the Beneficiary is strictly prohibited.

    Exceptionally, the individual storage and copying of parts of the content on a simple personal computer is allowed for strictly personal use, without the intention of commercial or other exploitation and always under the condition of indicating its source, without this meaning in any way the granting of rights of intellectual property. Everything else included in the web pages of www.buildingcenter.gr and constituting a registered trademark and copyrighted product of a third party falls within its own sphere of responsibility and has nothing to do with www.buildingcenter.gr.`,
    mh3: "Obligations of visitor - user",
    mb3: `The visitor - user of www.buildingcenter.gr must comply with the rules and provisions of Greek, European and International Law and the relevant legislation governing telecommunications. It must also refrain from any illegal and abusive use of the content and services of www.buildingcenter.gr. In addition, he must behave politely, politely and discreetly during his visit and use of www.buildingcenter.gr, while the adoption of unfair competition practices or other practices that oppose NETIQUETTE (Internet User Code of Conduct) is strictly prohibited. Any damage caused to www.buildingcenter.gr or to the Network in general resulting from the misuse or improper use of the relevant services by the user - visitor falls within the scope of his sole responsibility.

    In case of sending e-mails you should take care that:

    • The data and the content of the message does not offend the creators or other users of the website and the internet in general, and that it follows the laws, good manners and habits of internet use.

    • The user has made adequate efforts to remove viruses or other items that may harm the Website or other Internet users.

    • The messages will not contain advertising but only informative content.

    • The messages will respect human rights and the various minority groups.

    • The messages provided can be used by the Beneficiary without any legal requirement from the user providing them.
    `,
    mh4: "Network liability limitation",
    mb4: `The Beneficiary, without guaranteeing and therefore not being responsible, makes every effort to ensure that the information and all content are governed by the utmost accuracy, clarity, timeliness, completeness, accuracy and availability. In no case, including the case of negligence, is the Beneficiary responsible for any damage caused to the visitor - user on the occasion of this use of www.buildingcenter.gr.

    The information and services are provided "as is", without any express or implied warranty, all of which are explicitly denied by the Beneficiary, including marketability or suitability.

    The Beneficiary in no case guarantees the uninterrupted and error-free provision of its services and content, not even the lack of "viruses", whether it is www.buildingcenter.gr, or some other site or server through which the visitor - user receives its content.

    The Beneficiary reserves the right and sole discretion: (α) To make additions, improvements and/or modifications to any information and/or service and/or software provided on this Website, without notice;

    β) to cease the provision of any service and/or software and to refrain from publishing on its website and to delete from it any information or material provided or placed by the user, at any time without notice, mainly but not exclusively for reasons of functionality, security or other necessary reasons,

    γ) to use the suggestions or information available from users in any way it deems appropriate in the legal context of use (as in the case of personal data protection).
    `,
    mh5: "Exclusion of advice",
    mb5: `Anything provided to users - visitors through www.buildingcenter.gr is in no way, directly or indirectly, an incentive, advice or prompt for the performance of any action, but it is at the discretion of users - visitors to evaluate what is provided and to act based on their private will, excluding any responsibility of the Beneficiary.

    In the Technical Articles - which express the views of their author and not necessarily the beneficiary of www.buildingcenter.gr - the texts, tables, photos and sketches are not of an advertising nature and are not intended to promote corporate systems and products. They aim at providing technical information to the interested parties and are based on the bibliography mentioned at the end of each technical article.`,
    mh6: "Links to other sites",
    mb6: `www.buildingcenter.gr is not responsible for the content and services of other websites to which it refers through "links", hyperlinks or banner ads, nor does it guarantee their availability. Problems that may arise during the visit - use of the websites to which we refer are reduced exclusively to the sphere of responsibility of the respective websites, which the visitor - user must address. The referral to other websites is made for the convenience of users - visitors and in no case creates any form of commitment for anyone. This does not mean that the company is responsible for the content of the information, products and in general the data of these websites or that it provides any guarantee for them and especially for their completeness and correctness.`,
    mh7: "E-Mail Service",
    mb7: "The e-mail service from www.buildingcenter.gr is provided to those users - visitors who wish it, after previously filling out a relevant form with their specific personal information, some of which is mandatory and some optional, if they want it and only then. In this way they subscribe to the recipients of information from the e-mailservice ( e.g. Newsletters, Press Releases, etc.), without this meaning in any case that they acquire intellectual property rights, which in any case are protected by the relevant provisions of Greek law and international conventions and belong exclusively to the Beneficiary. It is at the discretion of www.buildingcenter.gr the registration or not of any interested party in the specific service, as well as its possible deletion. In case users no longer wish to receive such messages, they should inform the company by sending a message to www.buildingcenter.gr.",
    mh8: "Conversations",
    mb8: "On the Website it is possible to conduct conversations on the wider Internet based on the terms dictated by the INTERNET User Code of Conduct (NETIQUETTE). These conversations must be governed by decency, courtesy and discretion and must not offend persons or situations in any way. The views and perceptions expressed by users - visitors through this way in no case can be considered to be adopted by www.buildingcenter.gr. They are exclusively their personal perceptions, they give rise to intellectual property rights for them and the sole responsibility lies with those who express them each time. Possible violation of the above terms gives www.buildingcenter.gr the potential to exclude a user - visitor from this service.",
    mh9: "Forum",
    mb9: `The "forum" service provided by our Website gives the opportunity to those of the users - visitors who wish it to participate in discussions and reflections that arise from everyday issues and to express their views through text messages. The views expressed each time must be governed by decency, courtesy and discretion and must not offend people or situations in any way. The views and perceptions expressed by users - visitors through this way in no case can be considered to be adopted by www.buildingcenter.gr. They are exclusively their personal perceptions, they give rise to intellectual property rights for them and the sole responsibility lies with those who express them each time. Possible violation of the above terms gives www.buildingcenter.gr the potential to exclude a user - visitor from this service.`,
    mh10: "Privacy - Protection of personal data",
    mb10: `The management and protection of the personal data of the visitor - user of www.buildingcenter.gr is governed by the terms of the present and the relevant provisions of both Greek Law (Law 2472/1997, P.D. 207/1998. 79/2000, no. 8 L. 2819/2000 and 3471/2006), as well as of European Law (Directive 95/46/EC, Directive 97/66/EC, Regulation 2016/679).

    The personal data collected by www.buildingcenter.gr are the following:

    Mandatory

    - Name

    - Virtual name - Username

    - Password

    - E-Mail

    - Telephone

    This information is never disclosed to third parties (except where provided by law to the competent authorities only), but their personal character is preserved. www.buildingcenter.gr maintains files with this data exclusively for communication, statistical purposes and to improve its services

    In particular, regarding the chat and forum services, www.buildingcenter.gr may record the messages sent by the users - visitors in order to verify the existence or not of violations of the present terms, but in no case can share their content with third parties.

    In addition, in the case of "links" to other websites, www.buildingcenter.gr is not responsible for the terms of management and protection of personal data that they follow.

    In any case, the user - visitor of www.buildingcenter.gr has the opportunity, after contacting the competent department of www.buildingcenter.gr and ascertaining the existence of his personal archive to request its deletion, correction or change. Minors have access to www.buildingcenter.gr only with the consent of their parents - guardians and are not obliged to disclose their personal information. (For more details see below ΄PERSONAL DATA PROTECTION΄)`,
    mh11: "Compensation",
    mb11: "It is hereby expressly acknowledged that in the event that any lawsuit, claim, administrative or judicial proceeding against www.buildingcenter.gr arises from any form of visitor-user infringement, the user undertakes the obligation on the one hand to intervene in the relevant procedure and on the other hand to compensate www.buildingcenter.gr in case it is obliged to pay compensation or other expenses.",
    mh12: "Applicable law and other terms",
    mb12: `This contract of use is governed by the provisions of Greek Law, the directives and Regulations of European Law and the relevant International provisions, and is interpreted on the basis of the rules of good faith, transactional ethics and the social and economic purpose of the right in question.

    If a provision is deemed contrary to the law and therefore invalid or void, it automatically ceases to be valid, without in any case affecting the validity of the other terms.

    No modification of the terms of this specific contract will be considered valid and will not be part of it, if it has not been formulated in writing and has not been incorporated into it.

    Competent courts for any disputes arising on the occasion of the same contract are the Courts of Thessaloniki.
    `,
    sub_header: "PERSONAL DATA PROTECTION",
    mh13: "Preamble",
    mb13: "These terms of Personal Data Protection cover the conditions of processing the personal data of the visitors/users/members and generally traders collected by KTIRIO EDITIONS PC and its website during the visit and use of its services and pages (where in these terms the website www.buildingcenter.gr is mentioned, this report concerns the KTIRIO EDITIONS PC, as responsible for processing of personal data). These terms do not cover in any case the relationship between the visitors/users/members of www.buildingcenter.gr and generally persons trading with the KTIRIO EDITIONS PC and any services not subject to its control and/or ownership. Given the nature and volume of the Internet, under any circumstances, including the case of negligence, KTIRIO EDITIONS PC is not responsible for any sort and any nature of damage that the visitor/user of the pages, services, options and contents of www.buildingcenter.gr to which he proceeds on his own initiative and with the knowledge of the terms of this document.",
    mh14: "Collection and use of data",
    mb14: `www.buildingcenter.gr collects personal data: a) when the visitor/user subscribes to its services, b) when he uses its products and/or services and c) when he visits its pages and/or enters its promotional/advertising programs. When the visitor/user is registering at www.buildingcenter.gr the requested information is the following: Name, Telephone, City, Postal Code, Telephone, Year of birth, Occupation, E-mail address www.buildingcenter.gr uses the personal data of visitors/users/members for three general reasons:
    • informative support of its customers/users regarding the content and services of www.buildingcenter.gr.
    • the realization of the respective transactions of its customers/users regarding the services and products available from and through www.buildingcenter.gr.
    • informing them about new offers and promotions of services and products of KTIRIO EDITIONS PC or third party companies and professionals collaborating with it.
    `,
    mh15: "User - visitor account",
    mb15: "KTIRIO EDITIONS PC provides - through its website to its users member services, upon acceptance of the terms of use and completion of the process of registration of their data. Once the visitor/user completes the registration process, he will receive a confirmation of a personal password and the username that he has set. Users remain solely responsible for all operations carried out by their personal password, username and account in general (user account). Users agree to immediately notify KTIRIO EDITIONS PC for any unauthorized use of their account and any occurring and/or possible breach of security. Also, users are solely responsible for the careful use of their account and their formal exit from their account at the end of each use (Logout). KTIRIO EDITIONS PC is not responsible for any damage or loss resulting from the inability of user-visitors to comply with and follow this procedure. In any case that KTIRIO EDITIONS PC is made aware of any violation of the personal data of its registered users, it will inform the subject whose personal data have been illegally processed, as well as the Hellenic Data Protection Authority, within 48 hours from the moment the illegal processing in question was made aware by it.",
    mh16: "Security of Personal Data",
    mb16: "KTIRIO EDITIONS PC implements specific technical and organizational security procedures in order to protect personal data and information from loss, misuse, alteration or destruction.",
    mh17: "Access to personal data (Correction and/or deletion of personal data)",
    mb17: `If each user/member so wishes, he can request at any time to be informed about his personal data kept by KTIRIO EDITIONS PC, their recipients, the purpose of keeping and processing them as well as the modification, correction or deletion, by sending a relevant e-mail to the e-mail address info@buildingcenter.gr, from the e-mail address he has declared. He also has the right to review the personal data we hold and generally to exercise any right provided by law for the protection of personal data.
    The personal data disclosed to us by the user/member through www.buildingcenter.gr, either during his registration or at a later stage, are collected and used and processed in accordance with the applicable provisions on personal data protection and in particular, in accordance with the provisions of Law 2472/1997 and Law 3471/2006, as in force but also of the new General Data Protection Regulation (EU) 2016/679 as well as of the directive 95/46/EC, on the protection of personal data.
    In particular, each user/member, at his request has:
    • Right to information about the personal data we hold about him.
    • Right to correct his personal data.
    • The right to complete his personal data, provided that this data is necessary for the purposes of processing his data.
    • Right to delete his personal data. Some data will only be deleted after a specified retention period, for example because in some cases we are required by law to retain the data, or because the data is required to meet our contractual obligations to it.
    • Right to block his personal data, i.e. in certain cases provided by law, we will block his data, if requested by the user/member. Further processing of blocked data is done only to a very limited extent.
    • Right to withdraw his consent, which can be done at any time.
    • Right to object to the processing of his data. He may at any time object to the processing of his personal data in the future, if we process your data on the basis of one of the legal justifications provided for in Article 6 (1e or 1f) of Regulation (EU) 2016/679. If you object, we will stop processing your data, provided there are no legitimate grounds for further processing. The processing of your data for advertising purposes does not constitute a legitimate reason.`,
    mh18: "Transfer and disclosure of personal data",
    mb18: `KTIRIO EDITIONS PC undertakes not to transfer, sell, rent or in any way publish, notify and transmit the personal data of visitors/users/members of www.buildingcenter.grto any third party. www.buildingcenter.gr may channel personal data of its visitors/users/members to third-party legal and/or natural persons only if:
    • It has the explicit consent of visitors/users/members to channel personal data.
    • The transfer of personal data to legal and/or natural persons who cooperate with www.buildingcenter.gr becomes necessary for the implementation of the wishes and/or orders of users/members. Legal and natural persons who cooperate with www.buildingcenter.gr have the right to process the personal data that the users/members of www.buildingcenter.gr disclose to it only to the extent that it is absolutely necessary to provide support to www.buildingcenter.gr, in the context of this relationship with its visitors/users/members.
    • It is required due to compliance with the relevant provisions of the law and to the competent authorities only.
    `,
    mh19: "Advertising",
    mb19: "The visitor/user who is interested in advertising on www.buildingcenter.gr, in order to receive all the necessary information must fill in the relevant information in the relevant application: Name/Company Name - Telephone - E-mail - URL. www.buildingcenter.gr is not responsible for the personal data protection policy followed by the advertisers in their transactions with the visitors/users of these services.",
    mh20: "Cookies",
    mb20: "www.buildingcenter.gr may use cookies to identify the visitor/user of certain services and pages of www.buildingcenter.gr. Cookies are small text files that are stored on the hard drive of each visitor/user and do not receive knowledge of any document or file from his computer. They are used only to facilitate the access of the visitor/user to specific services of www.buildingcenter.gr and for statistical purposes in order to determine the areas in which the services of www.buildingcenter.gr are useful or popular or for marketing purposes. The visitor/user of www.buildingcenter.gr can configure his server (browser) in such a way that it either warns him about the use of cookies in specific services of www.buildingcenter.gr, or it does not allow the acceptance of use cookies in no case. In case the visitor/user of the specific services and pages of www.buildingcenter.gr does not wish the use of cookies for his/her identification, he/she cannot have further access to these services.",
    mh21: "Links to other sites",
    mb21: "www.buildingcenter.gr includes links to other websites (sites) which are not controlled by the same but by third parties (natural or legal persons). In no case is www.buildingcenter.gr responsible for the Terms of Protection of the Personal Data of the visitors/users that these bodies follow. KTIRIO EDITIONS PC is not responsible for the content of the information, products and in general the data of these websites and does not provide any guarantee for them and in particular for their completeness and correctness.",
    mh22: "IPaddresses",
    mb22: "The IP address through which the visitor/user's PC has access to the Internet and then to www.buildingcenter.gr is kept for technical reasons and is used exclusively for the collection of statistical data.",
    mh23: "Votes",
    mb23: "No personal information is required for the participation of the visitor/user in a poll conducted by www.buildingcenter.gr The vote of the visitor/user is recorded solely to draw conclusions regarding the position of public opinion on a particular issue. The votes and their results are the intellectual property of KTIRIO EDITIONS PC.",
    mh24: "Applicable law",
    mb24: "The management and protection of the personal data of the visitor/user of the services of www.buildingcenter.gr is subject to the terms of this, as well as to the relevant provisions of Greek and Community legislation, as applicable. These terms are formulated considering both the rapid development of technology, and in particular the Internet, and the existing - albeit underdeveloped - set of legal regulations on these issues. In this context, any possible relevant regulation will be the subject of this document. In any case, www.buildingcenter.gr reserves the right to change the terms of protection of personal data within the existing or future legal framework. If a visitor/user does not agree with the terms of protection of personal data provided herein must not use the services of www.buildingcenter.gr. Competent courts for any disputes arising on the occasion of the same contract are the Courts of Thessaloniki.",
  },
  bankInfo: {
    mh1: "BANK ACCOUNTS INFORMATION",
    sh1: "ADVERTISED",
    mb1: "Account Beneficiary: KTIRIO EDITIONS PC",
    bank1: "EUROBANK:",
    bank1IBAN: "ΙΒΑΝ: GR4202600380000540200391961",
    bank1SWIFT: "BANK SWIFT (BIC) : ERBKGRAA",
    bank2: "ALPHA BANK:",
    bank2IBAN: "IBAN: GR7801407120712002320000635",
    bank2SWIFT: "BANK SWIFT (BIC) : CRBAGRAA",
    bank3: "NATIONAL BANK OF GREECE:",
    bank3IBAN: "IBAN: GR5001102190000021947009888",
    bank3SWIFT: "BANK SWIFT (BIC) : ETHNGRAA",
  },
  privacy_policy: "Privacy Policy",
  bank_info: "Bank Information",
};
