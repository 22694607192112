import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import ReceptionConferenceHallSection from "./ReceptionConferenceHallSection";
import ReceptionFollowUsSection from "./ReceptionFollowUsSection";
import ScrollDownIcon from "../GenericLayout/ScrollDownIcon";
import ReceptionStandSection from "./ReceptionStandSection";
import { useRouterStore } from "../../store/useRouterStore";
import { RECEPTION } from "../../configs/NAVIGATION_PAGES";
import {
  ReceptionBg,
  ReceptionOverlay,
  ReceptionWrapper,
  ReceptionTopAlert,
  ReceptionLogoWrapper,
  ReceptionInnerWrapper,
  ReceptionContentPlaceholder,
  RightProgressBar,
} from "./Reception-custom-components/ReceptionCustomComponents";
import ReceptionButtons from "./ReceptionButtons";
import { Fade } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LogoSVG from "../GenericLayout/LogoSVG";
import { animateReception } from "../../animations/animateReception";
import getLiveData from "../../helpers/getLiveData";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import {
  ReceptionBgVideoInnerWrapper,
  ReceptionBgVideoOuterWrapper,
  ResponsiveTypographyBody,
  ResponsiveTypographyHeader,
  ResponsiveTypographySubheader,
  SimpleBox,
} from "../MaterialCustomComponents";
import { useWindowSize } from "../../hooks/useWindowSize";
import { updateRoute } from "../../helpers/updateBoothURL";
import { openStripes } from "../../animations/animateStripes";

const Reception = () => {
  const { setActiveCategory, setBoothID } = useRouterStore();
  const [showContent, setShowContent] = useState(false);
  const [liveData, setLiveData] = useState<any>({});
  const [nextWebinar, setNextWebinar] = useState("");
  const { t } = useTranslation();
  const {
    isReceptionAnimationFinished,
    setIsReceptionPageLoaded,
    setIsReceptionAnimationFinished,
  } = useComponentLoadingStateStore();
  const { height } = useWindowSize();
  const rightProgressBar: any = useRef();
  const wrapperRef: any = useRef();

  useEffect(() => {
    if (!isReceptionAnimationFinished) return;
    setIsReceptionPageLoaded(true);
    setIsReceptionAnimationFinished(false);
    setShowContent(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReceptionAnimationFinished]);

  useEffect(() => {
    // setIsReceptionPageLoaded(true);
    setBoothID("");
    animateReception(wrapperRef.current);
    setActiveCategory(RECEPTION);
    getLiveData().then(async (data = {}) => {
      if (!data?.title) return;
      setNextWebinar(`${data.title} - ${moment(data.date).format("DD.MM.YY")}`);
      setLiveData(data);
    });
    return () => setIsReceptionPageLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resize = () => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.height = `calc(${window.innerHeight}px - 44px)`;
  };

  useEffect(() => {
    if (!height) return;
    resize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  const handleScroll = () => {
    if (!wrapperRef.current || !rightProgressBar.current) return;
    const scrollPercentage =
      window.innerHeight /
      (wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight);
    rightProgressBar.current.style.height = `${
      wrapperRef.current.scrollTop * scrollPercentage
    }px`;
  };

  const link = {
    ...(liveData.goLive
      ? {
          header: "reception.top_alert.main_header_title_live",
          text: "reception.top_alert.watch_now_button",
          url: "#watch-live",
          onClick: () => openStripes(() => updateRoute("/conference_hall")),
          target: "",
        }
      : {
          onClick: () =>
            typeof window !== "undefined" &&
            window.open(liveData?.registerFormURL, "_blank"),
          header: "reception.top_alert.main_header_title",
          text: "reception.top_alert.register_button",
          url: liveData?.registerFormURL,
          target: "_blank",
        }),
  };

  return (
    <SimpleBox className="reception-page">
      <ReceptionBgVideoOuterWrapper className="video-bg">
        <ReceptionBgVideoInnerWrapper />
        <video
          src="/background-video.mp4"
          autoPlay
          muted
          loop
          style={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: -"1",
          }}
        />
      </ReceptionBgVideoOuterWrapper>
      <ReceptionBg className="reception-bg" />
      <ReceptionWrapper ref={wrapperRef} onScroll={() => handleScroll()}>
        <ReceptionOverlay>
          {link.url?.length > 0 ? (
            <ReceptionTopAlert onClick={link.onClick}>
              {nextWebinar && (
                <>
                  <SimpleBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {liveData?.goLive && (
                      <svg
                        className="live-svg"
                        width="20"
                        height="20"
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                      >
                        <circle cx="20" cy="15" r="10" />
                      </svg>
                    )}
                    <ResponsiveTypographyBody fontWeight="bold">
                      <Trans
                        components={{
                          span: <span></span>,
                        }}
                      >
                        {link.header}
                      </Trans>
                    </ResponsiveTypographyBody>
                  </SimpleBox>
                  <ResponsiveTypographyBody sx={{ marginTop: "1.2rem" }}>
                    {t("reception.top_alert.main_header", { nextWebinar })}
                  </ResponsiveTypographyBody>
                  <ResponsiveTypographyBody
                    sx={{ textDecoration: "underline" }}
                  >
                    {t(link.text)}
                  </ResponsiveTypographyBody>
                </>
              )}
              <RightProgressBar
                ref={rightProgressBar}
                sx={{ pointerEvents: "none" }}
              />
            </ReceptionTopAlert>
          ) : (
            <ReceptionTopAlert sx={{ pointerEvents: "none" }}>
              <RightProgressBar
                ref={rightProgressBar}
                sx={{ pointerEvents: "none", height: "0px" }}
              />
            </ReceptionTopAlert>
          )}
          {showContent ? (
            <Fade in={showContent} mountOnEnter unmountOnExit>
              <ReceptionInnerWrapper>
                <SimpleBox>
                  <ReceptionLogoWrapper>
                    <LogoSVG width={270} height={140} />
                  </ReceptionLogoWrapper>
                  <ResponsiveTypographyHeader fontWeight="bold">
                    {t("reception.main_section.main_header")}
                  </ResponsiveTypographyHeader>
                  <ReceptionButtons />
                  <SimpleBox>
                    <ResponsiveTypographySubheader fontWeight="bold">
                      {t("reception.main_section.main_subheader")}
                    </ResponsiveTypographySubheader>
                    <ScrollDownIcon />
                  </SimpleBox>
                </SimpleBox>
                <SimpleBox>
                  <ReceptionStandSection />
                </SimpleBox>
                <SimpleBox>
                  <ReceptionConferenceHallSection />
                </SimpleBox>
                <SimpleBox>
                  <ReceptionFollowUsSection />
                </SimpleBox>
              </ReceptionInnerWrapper>
            </Fade>
          ) : (
            <ReceptionContentPlaceholder />
          )}
        </ReceptionOverlay>
      </ReceptionWrapper>
    </SimpleBox>
  );
};

export default Reception;
