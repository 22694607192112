import { Box, Paper, styled, Typography } from "@mui/material";

export const MenuWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const SideNavWrapper = styled(Box)`
  position: absolute;
  z-index: 9;
  right: 0;
`;

export const NavigationWrapper = styled(Paper)(({ theme }) => ({
  width: "20rem",
  height: "100vh",
  padding: "3rem",
  backgroundColor: "rgba(230, 230, 230, 0.2)",
  [theme.breakpoints.down("sm")]: {
    width: "100vw",
  },
}));

export const NavigationBgWrapper = styled(Paper)`
  width: 20rem;
  height: calc(100vh - 2rem);
  padding: 2rem;
  margin: 0rem;
`;

export const NavigationLinksWrapper = styled(Box)`
  cursor: pointer;
`;

export const NavigationLinksTypography = styled(Typography)`
  font-weight: 700;
  &:hover {
    color: #e81c00;
    transition: all 0.25s ease-in-out;
  }
`;

export const FooterWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
