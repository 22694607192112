import { Box, styled } from "@mui/material";

export const GenericExpandedMenuWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "20rem 1fr",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
    justifyContent: "center",
  },
}));

export const GenericExpandedMenuContentWrapper = styled(Box)(({ theme }) => ({
  padding: "5rem 10rem",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "5rem 1rem",
  },
  [theme.breakpoints.up("lg")]: {
    overflowY: "scroll",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "2rem 1rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "2rem 1rem",
  },
}));

export const GenericLeftNavWrapper = styled(Box)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "1rem",
  borderRight: "1px solid lightgrey",
  [theme.breakpoints.down("lg")]: {
    height: "max-content",
  },
}));
