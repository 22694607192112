import { IRouterStore, arrowDirection } from "./interfaces/IRouterStore";
import create from "zustand";
import { H1 } from "../configs/NAVIGATION_PAGES";

const useRouterStore = create<IRouterStore>((set) => ({
  hallID: "1",
  lastTriggeredAt: new Date(),
  arrowDirection: "",
  boothID: "",
  activeCategory: "",
  customRoute: [],
  highlightedHall: H1,
  boothClickTriggeredFrom: "",
  setBoothClickTriggeredFrom: (boothClickTriggeredFrom) =>
    set(() => ({ boothClickTriggeredFrom })),
  setHighlightedHall: (highlightedHall) => set(() => ({ highlightedHall })),
  setCustomRoute: (customRoute) => set(() => ({ customRoute })),
  setActiveCategory: (activeCategory) => set(() => ({ activeCategory })),
  newTrigger: () => set(() => ({ lastTriggeredAt: Date.now() })),
  setArrowDirection: (direction: arrowDirection) =>
    set(() => ({ arrowDirection: direction })),
  setBoothID: (boothID: string) => set(() => ({ boothID })),
  setHallID: (hallID: string) => set(() => ({ hallID })),
}));

export { useRouterStore };
