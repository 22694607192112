import { useEffect, useRef } from "react";
import { animateScrolldownIcon } from "../../animations/animateScrolldownIcon";
import { ScrollDownIconWrapper } from "../Reception/Reception-custom-components/ReceptionCustomComponents";

const ScrollDownIcon = () => {
  const scrolldownRef = useRef();
  useEffect(() => {
    if (!scrolldownRef.current) return;
    animateScrolldownIcon(scrolldownRef.current);
  }, [scrolldownRef]);

  return (
    <ScrollDownIconWrapper ref={scrolldownRef}>
      <svg
        width="40"
        height="90"
        viewBox="0 0 26 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9996 17.853C12.3631 17.853 11.7526 17.6 11.3026 17.1497C10.8525 16.6994 10.5996 16.0886 10.5996 15.4517V10.6491C10.5996 10.0122 10.8525 9.40146 11.3026 8.95113C11.7526 8.5008 12.3631 8.2478 12.9996 8.2478C13.6361 8.2478 14.2466 8.5008 14.6967 8.95113C15.1468 9.40146 15.3996 10.0122 15.3996 10.6491V15.4517C15.3996 16.0886 15.1468 16.6994 14.6967 17.1497C14.2466 17.6 13.6361 17.853 12.9996 17.853Z"
          stroke="black"
          strokeWidth="1.87013"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 13.0505C25 9.86614 23.7357 6.81223 21.4853 4.56057C19.2348 2.30891 16.1826 1.04395 13 1.04395C9.8174 1.04395 6.76516 2.30891 4.51472 4.56057C2.26428 6.81223 1 9.86614 1 13.0505V25.057C1 28.2413 2.26428 31.2952 4.51472 33.5469C6.76516 35.7985 9.8174 37.0635 13 37.0635C16.1826 37.0635 19.2348 35.7985 21.4853 33.5469C23.7357 31.2952 25 28.2413 25 25.057V13.0505Z"
          stroke="black"
          strokeWidth="1.87013"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip0_912_9233)">
          <path
            d="M23.6757 48.1138L13.6518 58.1422C13.5867 58.2074 13.5095 58.2591 13.4244 58.2943C13.3394 58.3296 13.2483 58.3478 13.1562 58.3478C13.0641 58.3478 12.973 58.3296 12.888 58.2943C12.8029 58.2591 12.7257 58.2074 12.6606 58.1422L2.63672 48.1138"
            stroke="black"
            strokeWidth="1.87013"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip1_912_9233)">
          <path
            d="M23.6757 55.5984L13.6518 65.6268C13.5867 65.692 13.5095 65.7437 13.4244 65.779C13.3394 65.8142 13.2483 65.8324 13.1562 65.8324C13.0641 65.8324 12.973 65.8142 12.888 65.779C12.8029 65.7437 12.7257 65.692 12.6606 65.6268L2.63672 55.5984"
            stroke="black"
            strokeWidth="1.87013"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_912_9233">
            <rect
              width="22.4416"
              height="22.4538"
              fill="white"
              transform="translate(1.93555 41.2737)"
            />
          </clipPath>
          <clipPath id="clip1_912_9233">
            <rect
              width="22.4416"
              height="22.4538"
              fill="white"
              transform="translate(1.93555 48.7583)"
            />
          </clipPath>
        </defs>
      </svg>
    </ScrollDownIconWrapper>
  );
};

export default ScrollDownIcon;
