import { Button } from "@mui/material";
import { SimpleBox } from "../MaterialCustomComponents";
import { useEffect, useState } from "react";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { useWindowSize } from "../../hooks/useWindowSize";

const MenuSVG = () => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [isBurgerOpenLocal, setIsBurgerOpenLocal] = useState<boolean>();

  const { isBurgerOpen, isMenuExpanded } = useComponentLoadingStateStore();
  const { width } = useWindowSize();

  useEffect(() => {
    handleBurgerClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBurgerOpen, isMenuExpanded]);

  useEffect(() => {
    if (!isMenuExpanded) return;
    animateBurgerArrow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuExpanded]);

  const animateBurgerArrow = () => {
    if (width > 900) return;
    const burgerTop = document?.querySelector(".burger-middle");
    const burgerBottom = document?.querySelector(".burger-bottom");
    burgerTop?.classList.add("burger-middle-arrow");
    burgerBottom?.classList.add("burger-bottom-arrow");
  };

  const handleBurgerClick = () => {
    setIsBurgerOpenLocal((prev) => !prev);
    const burgerTop = document?.querySelector(".burger-top");
    const burgerMiddle = document?.querySelector(".burger-middle");
    const burgerBottom = document?.querySelector(".burger-bottom");
    const burgerText = document?.querySelector(".burger-text");
    burgerMiddle?.classList.remove("burger-middle-arrow");
    burgerBottom?.classList.remove("burger-bottom-arrow");
    if (isBurgerOpen) {
      burgerTop?.classList.add("burger-top-open");
      burgerMiddle?.classList.add("burger-middle-open");
      burgerBottom?.classList.add("burger-bottom-open");
      burgerText?.classList.add("burger-text-open");
      return;
    }
    burgerTop?.classList.remove("burger-top-open");
    burgerMiddle?.classList.remove("burger-middle-open");
    burgerBottom?.classList.remove("burger-bottom-open");
    burgerText?.classList.remove("burger-text-open");
  };

  return (
    <Button variant="controlPanel" onClick={handleBurgerClick}>
      <SimpleBox className="burger-wrapper">
        <SimpleBox className="burger-top"></SimpleBox>
        <SimpleBox className="burger-middle"></SimpleBox>
        <SimpleBox className="burger-bottom"></SimpleBox>
        <svg
          className="burger-text"
          width="32"
          height="8"
          viewBox="0 0 32 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.56175 1.58269C5.96457 1.16155 6.33536 0.859429 6.6741 0.676326C7.01284 0.484069 7.3882 0.387939 7.80018 0.387939C8.51428 0.387939 9.05443 0.607663 9.42064 1.04711C9.796 1.48656 9.98368 2.11368 9.98368 2.92849V6.91098H8.39069V3.16195C8.39069 2.70419 8.2854 2.34714 8.07483 2.0908C7.87342 1.83445 7.58046 1.70628 7.19594 1.70628C6.95791 1.70628 6.71529 1.77952 6.46811 1.926C6.22092 2.07249 5.99204 2.28763 5.78147 2.57144V6.91098H4.18848V3.21688C4.18848 2.69503 4.09692 2.31052 3.91382 2.06333C3.73072 1.81614 3.44233 1.69255 3.04866 1.69255C2.78316 1.69255 2.5314 1.76121 2.29336 1.89854C2.06448 2.03586 1.83103 2.26017 1.59299 2.57144V6.91098H0V0.511534H1.59299V1.37669C1.92258 1.03795 2.24301 0.790765 2.55428 0.635128C2.86556 0.470336 3.21803 0.387939 3.6117 0.387939C4.05115 0.387939 4.44024 0.488646 4.77898 0.690059C5.11772 0.882317 5.37864 1.17986 5.56175 1.58269Z"
            fill="white"
          />
          <path
            d="M17.3815 3.72499V3.97218H12.8909C12.9459 4.50318 13.1519 4.93347 13.5089 5.26305C13.866 5.58348 14.3191 5.7437 14.8684 5.7437C15.2804 5.7437 15.6878 5.67961 16.0907 5.55144C16.4935 5.42327 16.878 5.24474 17.2442 5.01586V6.30674C16.8322 6.56308 16.4111 6.75076 15.9808 6.86978C15.5597 6.97964 15.0973 7.03457 14.5938 7.03457C13.6142 7.03457 12.8131 6.7233 12.1906 6.10075C11.568 5.4782 11.2567 4.66797 11.2567 3.67006C11.2567 2.69046 11.5497 1.89854 12.1356 1.2943C12.7307 0.690059 13.5043 0.387939 14.4565 0.387939C15.3079 0.387939 16.0083 0.685481 16.5576 1.28057C17.1069 1.87565 17.3815 2.69046 17.3815 3.72499ZM15.8297 3.09328C15.8114 2.62637 15.6787 2.26017 15.4315 1.99467C15.1935 1.72917 14.8547 1.59642 14.4153 1.59642C13.985 1.59642 13.6279 1.73832 13.3441 2.02213C13.0603 2.29679 12.9092 2.65384 12.8909 3.09328H15.8297Z"
            fill="white"
          />
          <path
            d="M24.572 3.02462V6.91098H22.9653V3.18941C22.9653 2.62179 22.8508 2.2327 22.622 2.02213C22.3931 1.80241 22.1138 1.69255 21.7843 1.69255C21.2166 1.69255 20.7268 2.00382 20.3149 2.62637V6.91098H18.7081V0.511534H20.3149V1.36296C20.617 1.02422 20.9466 0.777033 21.3036 0.621395C21.6607 0.465758 22.0131 0.387939 22.361 0.387939C23.1026 0.387939 23.6565 0.625973 24.0227 1.10204C24.3889 1.57811 24.572 2.21897 24.572 3.02462Z"
            fill="white"
          />
          <path
            d="M27.7429 0.511534V4.2331C27.7429 4.78241 27.8436 5.1715 28.045 5.40038C28.2464 5.62926 28.5348 5.7437 28.9101 5.7437C29.194 5.7437 29.4594 5.6613 29.7066 5.49651C29.963 5.33172 30.1919 5.10284 30.3933 4.80987V0.511534H32V6.91098H30.3933V6.05955C30.1003 6.37998 29.7753 6.62259 29.4183 6.78738C29.0704 6.95217 28.7087 7.03457 28.3334 7.03457C27.5918 7.03457 27.0379 6.80111 26.6717 6.3342C26.3147 5.85814 26.1361 5.16234 26.1361 4.24683V0.511534H27.7429Z"
            fill="white"
          />
        </svg>
      </SimpleBox>
    </Button>
  );
};

export default MenuSVG;
