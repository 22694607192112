import gsap from "gsap";
import { useComponentLoadingStateStore } from "../store/useComponentLoadingStateStore";

export const animateReception = (element: HTMLElement) => {
  const tl = gsap.timeline();
  const videoElement: any = document.querySelector(".video-bg");
  const bgElement: any = document.querySelector(".reception-bg");
  tl.to(
    videoElement,
    {
      opacity: 0,
      duration: 1.3,
      delay: 0.3,
    },
    0
  )
    .to(
      bgElement,
      {
        opacity: 1,
        duration: 1,
      },
      0
    )
    .fromTo(
      element,
      {
        y: "100vh",
      },
      {
        y: "0",
        duration: 1.2,
        onComplete: () => {
          if (!videoElement) return;
          videoElement.style.display = "none";
          return;
        },
      },
      0.5
    )
    .to(
      element,
      {
        opacity: 1,
        duration: 1,
        onComplete: () => {
          useComponentLoadingStateStore.setState({
            isReceptionAnimationFinished: true,
            // isReceptionPageLoaded: true,
          });
          // debugger;
        },
      },
      1
    );
};
