import { MenuWrapper } from "../NavigationMenu/NavigationCustomComponents";
import Menu from "../NavigationMenu/Menu";
import StripeAnimation from "../Stripe-animation/StripeAnimation";
import { SimpleBox } from "../MaterialCustomComponents";
import { useRouter } from "next/router";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
// import MobileIndicators from "../Mobile-indicators/MobileIndicators";
const Layout = ({ children }: any) => {
  const { isBurgerOpen } = useComponentLoadingStateStore();

  const { route } = useRouter();
  return (
    <SimpleBox
      sx={{
        position: "relative",
        width: "100vw",
      }}
    >
      {/* <MobileIndicators /> */}
      <StripeAnimation />
      <MenuWrapper
        className="menu-wrapper"
        sx={{
          pointerEvents: isBurgerOpen ? "auto" : "none",
          opacity: route === "/conference_hall" ? 0 : 1,
        }}
      >
        <Menu />
      </MenuWrapper>
      {children}
    </SimpleBox>
  );
};

export default Layout;
