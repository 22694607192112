import { Box, Grid, styled } from "@mui/material";

export const ExpandedNavWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "calc(100vw - 20rem)",
  zIndex: 8,
  [theme.breakpoints.down("md")]: {
    width: "100vw",
    zIndex: 9,
  },
}));

export const ExpandedNavGridWrapper = styled(Grid)`
  height: calc(100vh - 2rem);
`;

export const ExpandedNavLeftColumn = styled(Box)`
  padding: 0rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ExpandedNavRightColumn = styled(Box)`
  padding: 7rem 2rem;
`;

export const ExpandedNavLogoWrapper = styled(Box)`
  padding: 1rem;
`;

export const ExpandedNavContantInfoWrapper = styled(Box)`
  padding: 1rem;
`;

export const ExpandedNavContantInfo = styled(Box)`
  padding-bottom: 2rem;
`;
