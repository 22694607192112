import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { closeStripes } from "../../animations/animateStripes";
import { useEffect } from "react";
import LoadingSVG from "./LoadingSVG";
import {
  Stripe,
  StripeLogoInnerWrapper,
  StripeLogoWrapper,
  StripesWrapper,
} from "./StripeCustomComponents";
import LogoSVG from "../GenericLayout/LogoSVG";

const StripeAnimation = () => {
  const {
    isModelLoaded,
    isReceptionPageLoaded,
    isExpoPageLoaded,
    isConferenceHallPageLoaded,
  } = useComponentLoadingStateStore();

  useEffect(() => {
    if (
      isReceptionPageLoaded ||
      isConferenceHallPageLoaded ||
      isExpoPageLoaded ||
      isModelLoaded
    ) {
      closeStripes();
    }
  }, [
    isReceptionPageLoaded,
    isConferenceHallPageLoaded,
    isExpoPageLoaded,
    isModelLoaded,
  ]);

  return (
    <StripesWrapper>
      <Stripe className="stripe-1" />
      <StripeLogoWrapper className="stripe-2">
        <StripeLogoInnerWrapper>
          <LogoSVG />
          <LoadingSVG />
        </StripeLogoInnerWrapper>
      </StripeLogoWrapper>
      <Stripe className="stripe-3" />
      <Stripe className="stripe-4" />
    </StripesWrapper>
  );
};

export default StripeAnimation;
