import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LEFT, RIGHT } from "../../configs/ARROWS_DIRECTION_CONFIG";
import { BLACK, HIGHLIGHT_COLOR } from "../../configs/NAVIGATION_PAGES";
import { arrowDirection } from "../../store/interfaces/IRouterStore";
import { useRouterStore } from "../../store/useRouterStore";
import {
  ChooseHallTypography,
  HallsInnerMobileWrapper,
  HallsInnerWrapper,
  HallsWrapper,
  SelectedHallTypography,
  SelectedHallWrapper,
} from "./ExhibitorsMapCustomComponents";
import ExhibitorsMapLeftNavBottomSection from "./ExhibitorsMapLeftNavBottomSection";
import LeftArrowIcon from "../GenericLayout/LeftArrowIcon";
import RightArrowIcon from "../GenericLayout/RightArrowIcon";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { SimpleBox } from "../MaterialCustomComponents";
import { useWindowSize } from "../../hooks/useWindowSize";
import { getExpoMapData, IExpoMapData } from "../../helpers/getExpoMapData";
import { getActiveRoom, getRooms } from "../../helpers/getData";
import { useTranslation } from "react-i18next";

const ExhibitorsMapLeftNavMiddleSection = () => {
  const { highlightedHall, setHighlightedHall } = useRouterStore();
  const { isMobileWidth, setIsMobileWidth } = useComponentLoadingStateStore();
  const [expoCategoriesData, setExpoCategoriesData] = useState<IExpoMapData[]>(
    []
  );
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const resizeWidth = (width?: number | any) => {
    if (width < 1200) {
      setIsMobileWidth(true);
    } else {
      setIsMobileWidth(false);
    }
  };

  useEffect(() => {
    getActiveRoom()
      .then((activeRoom) => getRooms(activeRoom))
      .then((rooms) => getExpoMapData(rooms))
      .then((data) => setExpoCategoriesData(data));
  }, []);

  useEffect(() => {
    resizeWidth(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const handleHallClick = (hall: number) => {
    if (!hall || isMobileWidth) return;
    setHighlightedHall(`H${hall}`);
  };

  const handleArrowClick = (direction: arrowDirection) => {
    const highlightedHallNumber = Number(highlightedHall[1]);
    if (!direction || !highlightedHallNumber) return;
    if (direction === LEFT && highlightedHallNumber > 1) {
      const leftHall = `H${highlightedHallNumber - 1}`;
      setHighlightedHall(leftHall);
    }
    if (direction === RIGHT && highlightedHallNumber < 6) {
      const rightHall = `H${highlightedHallNumber + 1}`;
      setHighlightedHall(rightHall);
    }
  };

  return (
    <HallsWrapper>
      <HallsInnerWrapper>
        <ChooseHallTypography>
          {t("exhibitors_map.main_header")}
        </ChooseHallTypography>
        {expoCategoriesData?.map(({ hallNumber, hallDescription }) => (
          <SelectedHallWrapper
            key={hallNumber}
            onClick={() => handleHallClick(hallNumber)}
            color={
              highlightedHall === `H${hallNumber}` ? HIGHLIGHT_COLOR : BLACK
            }
          >
            {!isMobileWidth && (
              <>
                <Typography variant="subtitle1" fontWeight="bold">
                  HALL {hallNumber}
                </Typography>
                <Typography variant="body1">{hallDescription}</Typography>
              </>
            )}
            {isMobileWidth && highlightedHall === `H${hallNumber}` && (
              <SimpleBox>
                <HallsInnerMobileWrapper>
                  <SimpleBox onClick={() => handleArrowClick(LEFT)}>
                    <LeftArrowIcon />
                  </SimpleBox>
                  <SelectedHallTypography variant="subtitle1" fontWeight="bold">
                    HALL {hallNumber}
                  </SelectedHallTypography>
                  <SimpleBox onClick={() => handleArrowClick(RIGHT)}>
                    <RightArrowIcon />
                  </SimpleBox>
                </HallsInnerMobileWrapper>
                <Typography variant="body1">{hallDescription}</Typography>
              </SimpleBox>
            )}
          </SelectedHallWrapper>
        ))}
      </HallsInnerWrapper>
      {!isMobileWidth && <ExhibitorsMapLeftNavBottomSection />}
    </HallsWrapper>
  );
};

export default ExhibitorsMapLeftNavMiddleSection;
