const MapSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="map-svg"
        d="M8.86364 16.8439L2.5 20.0439V7.24395L8.86364 4.04395M8.86364 16.8439L16.1364 20.0439M8.86364 16.8439V4.04395M16.1364 20.0439L22.5 16.8439V4.04395L16.1364 7.24395M16.1364 20.0439V7.24395M16.1364 7.24395L8.86364 4.04395"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MapSVG;
