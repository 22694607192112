import { FormControl } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorBox } from "../GenericLayout/ErrorBox";
import { SuccessBox } from "../GenericLayout/SuccessBox";
import { SimpleBox } from "../MaterialCustomComponents";
import {
  ConfirmButton,
  CustomInput,
} from "../MaterialCustomComponents/NewsletterComponents";

const FormSection = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [fetching, setFetching] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const subscribeUser = async (e: React.FormEvent<HTMLFormElement>) => {
    setFetching(true);
    setComplete(false);
    setErrorMsg("");
    e.preventDefault();

    // this is where your mailchimp request is made
    const res = await fetch("/api/subscribeUser", {
      body: JSON.stringify({
        email,
      }),

      headers: {
        "Content-Type": "application/json",
      },

      method: "POST",
    });
    setFetching(false);
    try {
      const resHasError = (await res.json()).error;
      if (resHasError) {
        return setErrorMsg(resHasError);
      }
      return setComplete(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <SimpleBox
      sx={{
        paddingBottom: "2rem",
      }}
    >
      <form onSubmit={subscribeUser}>
        <FormControl variant="standard" sx={{ gap: "16px" }}>
          <CustomInput
            id="email"
            type="email"
            required={true}
            value={email}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            placeholder={t("newsletter.main_input_placeholder") as string}
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "black",
                },
              },
            }}
          />

          <ConfirmButton variant="primary" type="submit" disabled={fetching}>
            {t("newsletter.main_button")}
          </ConfirmButton>
          {errorMsg.length > 0 && (
            <ErrorBox msg={t("newsletter.error_message")} />
          )}
          {complete && <SuccessBox msg={t("newsletter.success_message")} />}
        </FormControl>
      </form>
    </SimpleBox>
  );
};

export default FormSection;
