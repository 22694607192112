import Image from "next/image";
import { useTranslation } from "react-i18next";
import { openStripes } from "../../animations/animateStripes";
import { updateRoute } from "../../helpers/updateBoothURL";
import {
  ResponsiveTypographyBody,
  ResponsiveTypographyButton,
  ResponsiveTypographyHeader,
  SimpleBox,
} from "../MaterialCustomComponents";
import {
  ReceptionConferenceHallSectionWrapper,
  ReceptionTextUnderline,
  ReceptionButton,
  ReceptionImageWrapper,
} from "./Reception-custom-components/ReceptionCustomComponents";

const ReceptionConferenceHallSection = () => {
  const { t } = useTranslation();
  return (
    <ReceptionConferenceHallSectionWrapper>
      <ResponsiveTypographyHeader fontWeight="bold">
        {t("reception.conference_section.main_conference_header")}
      </ResponsiveTypographyHeader>
      <ReceptionTextUnderline />
      <ReceptionImageWrapper>
        <Image
          src="/conference-bg.jpg"
          fill
          alt="conference hall image"
          style={{ objectFit: "contain" }}
        />
      </ReceptionImageWrapper>
      <SimpleBox>
        <ResponsiveTypographyBody>
          {t("reception.conference_section.main_conference_description")}
        </ResponsiveTypographyBody>
      </SimpleBox>
      <ReceptionButton
        variant="primary"
        onClick={() => openStripes(() => updateRoute("/conference_hall"))}
      >
        <Image
          src="/video-player.png"
          width={20}
          height={20}
          alt="video player"
        />
        <ResponsiveTypographyButton fontWeight="bold">
          {t("reception.conference_section.main_conference_button")}
        </ResponsiveTypographyButton>
        <Image
          src="/go-to-right-arrow.png"
          width={20}
          height={20}
          alt="go to hall arrow"
        />
      </ReceptionButton>
    </ReceptionConferenceHallSectionWrapper>
  );
};

export default ReceptionConferenceHallSection;
