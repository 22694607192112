import Router from "next/router";
import { useTranslation } from "react-i18next";
declare global {
  interface Window {
    klaro: any;
  }
}
export const CookiesSection = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        marginBottom: "20px",
      }}
    >
      {/* <p
        id="cookiesSettings"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          window.klaro ??= { show: () => console.info("cookie not found") };
          window.klaro.show();
        }}
      >
        Cookie Settings
      </p> */}
      <p
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          Router.push("/terms");
        }}
      >
        {t("privacy_policy")}
      </p>
      <p
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          Router.push("/bank-info");
        }}
      >
        {t("bank_info")}
      </p>
    </div>
  );
};
