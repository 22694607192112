import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import GenericLeftNav from "../GenericLayout/GenericLeftNav";
import { SimpleBox } from "../MaterialCustomComponents";

const About = () => {
  const { t } = useTranslation();
  return (
    <GenericExpandedMenuWrapper>
      <GenericLeftNav />
      <GenericExpandedMenuContentWrapper>
        <Typography variant="h4" fontWeight="bold">
          {t("about.about_main_header")}
        </Typography>
        <Typography variant="subtitle2">
          {t("about.about_main_subheader")}
        </Typography>
        <SimpleBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography variant="body1">
            <Trans>about.about_first_paragraph</Trans>
          </Typography>
          <Typography variant="body1">
            <Trans>about.about_second_paragraph</Trans>
          </Typography>
          <Typography variant="body1">
            {t("about.about_third_paragraph")}
          </Typography>
        </SimpleBox>
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

export default About;
