import {
  FollowUsSectionButtonsWrapper,
  FollowUsSectionWrapper,
  ReceptionButton,
  ReceptionTextUnderline,
} from "./Reception-custom-components/ReceptionCustomComponents";
import Image from "next/image";
import { useRouterStore } from "../../store/useRouterStore";
import { CONTACT, NEWSLETTER } from "../../configs/NAVIGATION_PAGES";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import LogoSVG from "../GenericLayout/LogoSVG";
import FacebookIconSVG from "../GenericLayout/FacebookIconSVG";
import InstagramIconSVG from "../GenericLayout/InstagramIconSVG";
import YoutubeIconSVG from "../GenericLayout/YoutubeIconSVG";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import {
  ResponsiveTypographyBody,
  ResponsiveTypographyButton,
  ResponsiveTypographyHeader,
  SimpleBox,
} from "../MaterialCustomComponents";
import { CookiesSection } from "./ReceptionCookiesSection";

const ReceptionFollowUsSection = () => {
  const { setIsSideNavOpen, setIsMenuExpanded } =
    useComponentLoadingStateStore();
  const { setActiveCategory } = useRouterStore();
  const { t } = useTranslation();
  const handleClick = (viewPage: string) => {
    setActiveCategory(viewPage);
    setIsSideNavOpen(true);
    setIsMenuExpanded(true);
  };
  return (
    <FollowUsSectionWrapper>
      <ResponsiveTypographyHeader fontWeight="bold">
        {t("reception.follow_section.main_follow_header")}
      </ResponsiveTypographyHeader>
      <ReceptionTextUnderline />
      <SimpleBox sx={{ width: "100%" }}>
        <LogoSVG width={200} height={100} />
      </SimpleBox>
      <SimpleBox>
        <ResponsiveTypographyBody>
          {t("reception.follow_section.main_follow_description_department")}
        </ResponsiveTypographyBody>
        <ResponsiveTypographyBody>
          {t("reception.follow_section.main_follow_description_phone")}
        </ResponsiveTypographyBody>
        <ResponsiveTypographyBody>
          {t("reception.follow_section.main_follow_description_email")}
        </ResponsiveTypographyBody>
        <SimpleBox
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
            margin: "1rem",
          }}
        >
          <Link
            href="https://www.facebook.com/buildingcenter.gr"
            target="blank"
          >
            <FacebookIconSVG />
          </Link>
          <Link
            href="https://www.instagram.com/buildingcenter.gr/"
            target="blank"
          >
            <InstagramIconSVG />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UC2S285PD5Y1IEkChwCEqAhQ"
            target="blank"
          >
            <YoutubeIconSVG />
          </Link>
        </SimpleBox>
      </SimpleBox>
      <FollowUsSectionButtonsWrapper>
        <ReceptionButton variant="primary" onClick={() => handleClick(CONTACT)}>
          <Image src="/email.png" width={20} height={20} alt="contact us" />
          <ResponsiveTypographyButton fontWeight="bold">
            {t("reception.follow_section.main_follow_contact_button")}
          </ResponsiveTypographyButton>
          <Image
            src="/go-to-right-arrow.png"
            width={20}
            height={20}
            alt="go to hall arrow"
          />
        </ReceptionButton>
        <ReceptionButton
          variant="primary"
          onClick={() => handleClick(NEWSLETTER)}
        >
          <Image
            src="/newsletter.png"
            width={20}
            height={20}
            alt="join our newsletter"
          />
          <ResponsiveTypographyButton fontWeight="bold">
            {t("reception.follow_section.main_follow_newsletter_button")}
          </ResponsiveTypographyButton>
          <Image
            src="/go-to-right-arrow.png"
            width={20}
            height={20}
            alt="go to hall arrow"
          />
        </ReceptionButton>
      </FollowUsSectionButtonsWrapper>
      <CookiesSection />
    </FollowUsSectionWrapper>
  );
};

export default ReceptionFollowUsSection;
