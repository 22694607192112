import { Typography } from "@mui/material";
import FormSection from "./FormSection";
import GenericLeftNav from "../GenericLayout/GenericLeftNav";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import { NewsletterInnerWrapper } from "./NewsletterCustomComponents";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <GenericExpandedMenuWrapper>
      <GenericLeftNav />
      <GenericExpandedMenuContentWrapper>
        <Typography variant="h4" fontWeight="bold">
          {t("newsletter.main_header")}
        </Typography>
        <Typography variant="subtitle2">
          {t("newsletter.main_subheader")}
        </Typography>
        <NewsletterInnerWrapper>
          <Typography variant="h6" fontWeight="bold">
            {t("newsletter.main_input_header")}
          </Typography>
        </NewsletterInnerWrapper>
        <FormSection />
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

export default Newsletter;
