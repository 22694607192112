import { Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import GenericLeftNav from "../GenericLayout/GenericLeftNav";
import { SimpleBox } from "../MaterialCustomComponents";

const Visitors = () => {
  const { t } = useTranslation();
  return (
    <GenericExpandedMenuWrapper>
      <GenericLeftNav />
      <GenericExpandedMenuContentWrapper>
        <Typography variant="h4" fontWeight="bold">
          {t("visitors.main_header")}
        </Typography>
        <Typography variant="subtitle2">
          {t("visitors.main_subheader")}
        </Typography>
        <SimpleBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            paddingTop: "3rem",
          }}
        >
          <Typography variant="body1">
            <Trans>visitors.first_paragraph</Trans>
          </Typography>
          <Typography variant="body1">
            <Trans>visitors.second_paragraph</Trans>
          </Typography>
          <Typography variant="body1">
            {t("visitors.third_paragraph")}
          </Typography>
        </SimpleBox>
        <SimpleBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            paddingTop: "3rem",
          }}
        >
          <Typography variant="body1">
            {t("visitors.fourth_paragraph")}
          </Typography>
          <Typography variant="body1">
            {t("visitors.fifth_paragraph")}
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {t("visitors.footer_section_header")}
          </Typography>
          <Stack sx={{ paddingBottom: "2rem" }}>
            <Trans components={{ li: <li></li>, strong: <strong></strong> }}>
              visitors.footer_section_categories
            </Trans>
          </Stack>
        </SimpleBox>
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

export default Visitors;
