import { useWindowSize } from "../../hooks/useWindowSize";
import ExhibitorsMapLeftNavBottomSection from "../Exhibitors-map/ExhibitorsMapLeftNavBottomSection";
import { SimpleBox } from "../MaterialCustomComponents";
import { GenericLeftNavWrapper } from "./GenericCustomComponents";
import TransparentLogoSVG from "./TransparentLogoSVG";

const GenericLeftNav = ({ showInfo = true }) => {
  const { width } = useWindowSize();
  return (
    <GenericLeftNavWrapper>
      <SimpleBox>
        <TransparentLogoSVG />
      </SimpleBox>
      {width >= 1200 && showInfo && <ExhibitorsMapLeftNavBottomSection />}
    </GenericLeftNavWrapper>
  );
};

export default GenericLeftNav;
