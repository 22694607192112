import { SimpleBox } from "../MaterialCustomComponents";
import Image from "next/image";

const TransparentLogoSVG = () => {
  return (
    <SimpleBox>
      <Image
        src="/logo-transparent.svg"
        alt="ktirio logo"
        width={155}
        height={83}
        priority={true}
      />
    </SimpleBox>
  );
};

export default TransparentLogoSVG;
