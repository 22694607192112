import { ThreeEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { HALL_ID } from "../configs/HALLS_CONFIG";
import Router from "next/router";
import { Object3D } from "three";

export const updateBoothURL = (
  e: ThreeEvent<Object3D> | null,
  exactPos: undefined | string = undefined
) => {
  Router.push(
    `/expo/halls/${Router.query[HALL_ID]}/booths/${
      exactPos ? exactPos : e?.eventObject.name
    }`,
    undefined,
    {
      shallow: true,
    }
  );
};

export const replaceBoothURL = () => {
  Router.replace(
    `/expo/halls/${Router.query[HALL_ID] ? Router.query[HALL_ID] : 1}/booths`,
    undefined,
    {
      shallow: true,
    }
  );
};

export const updateHallURL = (hallID: string) => {
  if (!hallID) return;
  Router.push(`/expo/halls/${hallID}/booths`, undefined, { shallow: true });
};

// TODO is the updateHallAndBoothURL function any different than the updateRoute below it?
// TODO probably should be removed
export const updateHallAndBoothURL = (hallID: string, boothID: string) => {
  if (!hallID || !boothID) return;
  Router.push(`/expo/halls/${hallID}/booths/${boothID}`, undefined, {
    shallow: true,
  });
};

export const updateRoute = (route: string) => {
  if (!route) return;
  Router.push(`${route}`, undefined, {
    shallow: true,
  });
};
