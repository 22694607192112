import { IRoomsData } from "./interfaces/IRoomsData";

export const ROOMS_DATA: IRoomsData = {
  H1: [],
  H2: [],
  H3: [],
  H4: [],
  H5: [],
  H6: [],
};
