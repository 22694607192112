import {
  ReceptionButton,
  ReceptionButtonWrapper,
} from "./Reception-custom-components/ReceptionCustomComponents";
import Image from "next/image";
import {
  CONFERENCE_HALL,
  EXHIBITION_HALL,
  EXHIBITORS_MAP,
  OFFERS,
} from "../../configs/NAVIGATION_PAGES";
import MapSVG from "./Reception-svgs/MapSVG";
import OffersSVG from "./Reception-svgs/OffersSVG";
import {
  highlightMapSVG,
  removeHighlightMapSVG,
  highlightOffersSVG,
  removeHighlightOffersSVG,
} from "../../animations/animateHighlightSVG";
import { openStripes } from "../../animations/animateStripes";
import { updateRoute } from "../../helpers/updateBoothURL";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { useRouterStore } from "../../store/useRouterStore";
import { useTranslation } from "react-i18next";
import { ResponsiveTypographyButton } from "../MaterialCustomComponents";

const ReceptionButtons = () => {
  const { setIsSideNavOpen, setIsMenuExpanded } =
    useComponentLoadingStateStore();
  const { setActiveCategory } = useRouterStore();
  const { t } = useTranslation();
  const handleButtonClick = (route: string) => {
    switch (route) {
      case EXHIBITION_HALL:
        const hallNumber = Math.floor(Math.random() * 6) + 1;
        openStripes(() => updateRoute(`/expo/halls/${hallNumber}/booths`));
        break;
      case CONFERENCE_HALL:
        openStripes(() => updateRoute("/conference_hall"));
        break;
      case EXHIBITORS_MAP:
        setIsSideNavOpen(true);
        setActiveCategory(EXHIBITORS_MAP);
        setIsMenuExpanded(true);
        break;
      case OFFERS:
        setIsSideNavOpen(true);
        setActiveCategory(OFFERS);
        setIsMenuExpanded(true);
        break;
    }
  };

  return (
    <>
      <ReceptionButtonWrapper>
        <ReceptionButton
          variant="primary"
          onClick={() => handleButtonClick(EXHIBITION_HALL)}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1132_6655)">
              <path
                d="M12.5 11.2939C13.163 11.2939 13.7989 11.0306 14.2678 10.5617C14.7366 10.0929 15 9.45699 15 8.79395V7.54395C15 6.8809 14.7366 6.24502 14.2678 5.77618C13.7989 5.30734 13.163 5.04395 12.5 5.04395H11.25V11.2939H12.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 6.29395C5 5.96242 5.1317 5.64448 5.36612 5.41006C5.60054 5.17564 5.91848 5.04395 6.25 5.04395H7.19583C7.61023 5.04483 8.00731 5.2103 8.29971 5.50395C8.59211 5.7976 8.75588 6.19538 8.755 6.60978C8.75412 7.02418 8.58865 7.42126 8.295 7.71366C8.00135 8.00606 7.60357 8.16983 7.18917 8.16895C7.60357 8.16806 8.00135 8.33183 8.295 8.62423C8.58865 8.91663 8.75412 9.31371 8.755 9.72811C8.75588 10.1425 8.59211 10.5403 8.29971 10.8339C8.00731 11.1276 7.61023 11.2931 7.19583 11.2939H6.25C5.91848 11.2939 5.60054 11.1622 5.36612 10.9278C5.1317 10.6934 5 10.3755 5 10.0439"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.5417 1.29395H1.45833C0.998096 1.29395 0.625 1.66704 0.625 2.12728V14.2106C0.625 14.6708 0.998096 15.0439 1.45833 15.0439H18.5417C19.0019 15.0439 19.375 14.6708 19.375 14.2106V2.12728C19.375 1.66704 19.0019 1.29395 18.5417 1.29395Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.875 18.7939H8.125L8.75 15.0439H11.25L11.875 18.7939Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.625 18.7939H14.375"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1132_6655">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0 0.0439453)"
                />
              </clipPath>
            </defs>
          </svg>

          <ResponsiveTypographyButton fontWeight="bold">
            {t("reception.main_section.buttons.hall_button")}
          </ResponsiveTypographyButton>
          <Image
            src="/go-to-right-arrow.png"
            width={20}
            height={20}
            alt="go to hall arrow"
          />
        </ReceptionButton>
        <ReceptionButton
          variant="primary"
          onClick={() => handleButtonClick(CONFERENCE_HALL)}
        >
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1132_6665)">
              <path
                d="M9.1175 7.62048C8.99913 7.56504 8.8691 7.53905 8.73851 7.54471C8.60792 7.55036 8.48064 7.58751 8.3675 7.65298C8.25711 7.71485 8.165 7.80476 8.10048 7.91362C8.03595 8.02248 8.00129 8.14644 8 8.27298V11.8138C8.00115 11.9404 8.03565 12.0644 8.10002 12.1734C8.16439 12.2824 8.25637 12.3725 8.36667 12.4346C8.47985 12.5 8.60712 12.5371 8.73769 12.5427C8.86825 12.5484 8.99826 12.5224 9.11667 12.4671L12.4392 10.898C12.6055 10.8255 12.747 10.7061 12.8464 10.5544C12.9459 10.4027 12.9988 10.2252 12.9988 10.0438C12.9988 9.86241 12.9459 9.68496 12.8464 9.53323C12.747 9.38149 12.6055 9.26208 12.4392 9.18965L9.1175 7.62048Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.1008 2.54395H4.89917C4.54064 2.54395 4.25 2.83459 4.25 3.19311V16.8948C4.25 17.2533 4.54064 17.5439 4.89917 17.5439H16.1008C16.4594 17.5439 16.75 17.2533 16.75 16.8948V3.19311C16.75 2.83459 16.4594 2.54395 16.1008 2.54395Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.11169 4.41895C1.98124 4.41982 1.85224 4.44638 1.73206 4.4971C1.61188 4.54783 1.50286 4.62173 1.41124 4.71459C1.31962 4.80745 1.24719 4.91745 1.19808 5.0383C1.14897 5.15916 1.12414 5.2885 1.12502 5.41895V14.6689C1.12414 14.7994 1.14897 14.9287 1.19808 15.0496C1.24719 15.1704 1.31962 15.2804 1.41124 15.3733C1.50286 15.4662 1.61188 15.5401 1.73206 15.5908C1.85224 15.6415 1.98124 15.6681 2.11169 15.6689"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.8887 4.41895C19.0191 4.41982 19.1481 4.44638 19.2683 4.4971C19.3885 4.54783 19.4975 4.62173 19.5891 4.71459C19.6807 4.80745 19.7532 4.91745 19.8023 5.0383C19.8514 5.15916 19.8762 5.2885 19.8753 5.41895V14.6689C19.8762 14.7994 19.8514 14.9287 19.8023 15.0496C19.7532 15.1704 19.6807 15.2804 19.5891 15.3733C19.4975 15.4662 19.3885 15.5401 19.2683 15.5908C19.1481 15.6415 19.0191 15.6681 18.8887 15.6689"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1132_6665">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.5 0.0439453)"
                />
              </clipPath>
            </defs>
          </svg>

          <ResponsiveTypographyButton fontWeight="bold">
            {t("reception.main_section.buttons.conference_button")}
          </ResponsiveTypographyButton>
          <Image
            src="/go-to-right-arrow.png"
            width={20}
            height={20}
            alt="go to hall arrow"
          />
        </ReceptionButton>
      </ReceptionButtonWrapper>
      <ReceptionButtonWrapper>
        <ReceptionButton
          variant="outlined"
          onClick={() => handleButtonClick(EXHIBITORS_MAP)}
          onMouseOver={() => highlightMapSVG()}
          onMouseLeave={() => removeHighlightMapSVG()}
        >
          <MapSVG />
          <ResponsiveTypographyButton fontWeight="bold">
            {t("reception.main_section.buttons.map_button")}
          </ResponsiveTypographyButton>
        </ReceptionButton>
        <ReceptionButton
          variant="outlined"
          onClick={() => handleButtonClick(OFFERS)}
          onMouseOver={() => highlightOffersSVG()}
          onMouseLeave={() => removeHighlightOffersSVG()}
        >
          <OffersSVG />
          <ResponsiveTypographyButton fontWeight="bold">
            {t("reception.main_section.buttons.offers_button")}
          </ResponsiveTypographyButton>
        </ReceptionButton>
      </ReceptionButtonWrapper>
    </>
  );
};

export default ReceptionButtons;
