import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const SuccessBoxStyled = styled(Typography)`
  color: green;
  padding: 20px;
  border: 1px solid green;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 2px;
  margin: 10px 0px;
`;
export const SuccessBox = ({ msg }: { msg: string }) => {
  return <SuccessBoxStyled>{msg}</SuccessBoxStyled>;
};
