import { collection, getDocs, query } from "firebase/firestore";
import { database } from "../firebase/connectFirebase";

const getOffersData = async () => {
  const activeRoom = (
    await getDocs(query(collection(database, "appSettings")))
  ).docs.map((doc) => doc.data())[0].active;

  const offers = (
    await getDocs(collection(database, "expos", activeRoom, "offers"))
  ).docs.map((doc) => doc.data());

  return offers;
};
export default getOffersData;
