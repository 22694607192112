import { ExhibitorsMapLeftNavTopWrapper } from "./ExhibitorsMapCustomComponents";
import TransparentLogoSVG from "../GenericLayout/TransparentLogoSVG";
import { SimpleBox } from "../MaterialCustomComponents";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ExhibitorsMapLeftNavTopSection = () => {
  const { t } = useTranslation();
  return (
    <SimpleBox>
      <TransparentLogoSVG />
      <ExhibitorsMapLeftNavTopWrapper>
        <Typography variant="subtitle1" fontWeight="bold">
          {t("exhibitors_map.main_top_header")} 01/07 - 31/12
        </Typography>
      </ExhibitorsMapLeftNavTopWrapper>
    </SimpleBox>
  );
};

export default ExhibitorsMapLeftNavTopSection;
