import { List, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { animateShowBlackBanner } from "../../animations/animateBlackBanner";
import { openStripes } from "../../animations/animateStripes";
import { HIGHLIGHT_COLOR } from "../../configs/NAVIGATION_PAGES";
import getOffersData from "../../helpers/getOffersData";
import { updateHallURL } from "../../helpers/updateBoothURL";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { useRouterStore } from "../../store/useRouterStore";
import { SimpleBox } from "../MaterialCustomComponents";
import { useTranslation } from "react-i18next";
interface ItemType {
  title: string;
  offerDescription: string;
  standDescription: string;
  hallNumber: string;
  noResults?: boolean;
}

const Item = ({ item }: { item: ItemType }) => {
  const {
    setIsMenuExpanded,
    setIsSideNavOpen,
    setIsBurgerOpen,
    setIsExhibitorsMapIconClicked,
  } = useComponentLoadingStateStore();
  const { setBoothClickTriggeredFrom, hallID } = useRouterStore();
  const { pathname } = useRouter();
  const basePath = pathname.split("/")[1];
  const handleImageClick = (boothName: string) => {
    if (!boothName || item.noResults) return;
    setIsExhibitorsMapIconClicked(true);

    if (basePath === "expo") {
      setBoothClickTriggeredFrom("expo");
      setIsMenuExpanded(false);
      setIsSideNavOpen(false);
      setIsBurgerOpen(false);
      if (Number(hallID) === Number(item.hallNumber)) return;
      animateShowBlackBanner();
      updateHallURL(item.hallNumber);
      return;
    }
    setBoothClickTriggeredFrom("global");
    openStripes(() => {
      setIsMenuExpanded(false);
      setIsSideNavOpen(false);
      setIsBurgerOpen(false);
      updateHallURL(item.hallNumber);
    });
  };
  return (
    <SimpleBox
      sx={{
        paddingBottom: "12px",
        "&:hover": {
          color: HIGHLIGHT_COLOR,
          cursor: "pointer",
          transition: "all 250ms ease-in-out",
        },
      }}
      onClick={() => handleImageClick(item.standDescription)}
    >
      <Typography variant="h6" fontWeight="600">
        {item.title}
      </Typography>

      <Typography variant="body2">{item.offerDescription}</Typography>
      {!item.noResults && (
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            width: "max-content",
            textDecoration: "underline",
          }}
        >
          Go to offer
        </Typography>
      )}
    </SimpleBox>
  );
};

const Results = () => {
  const [t] = useTranslation();
  const [offers, setOffers] = useState<ItemType[]>([]);
  useEffect(() => {
    getOffersData().then((data) => {
      setOffers(data as unknown as ItemType[]);
    });
  }, []);

  return (
    <List
      component="div"
      sx={{
        marginTop: "1rem",
        maxHeight: "60vh",
        overflow: "auto",
        paddingBottom: "2rem",
      }}
    >
      {offers.map((item: any) => (
        <Item key={item.id} item={item} />
      ))}
      {!offers.length && (
        <Item
          key={1}
          item={{
            title: t("offers.no_results_title"),
            offerDescription: t("offers.no_results_subtitle"),
            standDescription: "string",
            hallNumber: "string",
            noResults: true,
          }}
        />
      )}
    </List>
  );
};

export default Results;
