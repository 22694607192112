export interface IExpoMapData {
  hallDescription: string;
  hallDetails: string;
  hallNumber: number;
}

export const getExpoMapData = (data: any): Promise<IExpoMapData[]> =>
  new Promise((resolve) => {
    const extractedExpoMapData = data.map(
      ({ hallDescription, hallDetails, title }: any) => {
        return {
          hallDescription,
          hallDetails,
          hallNumber: Number(title.split("Room_")[1]),
        };
      }
    );
    const sortedExpoMapData = extractedExpoMapData.sort(
      (currentItem: IExpoMapData, nextItem: IExpoMapData) => {
        if (currentItem.hallNumber < nextItem.hallNumber) return -1;
        if (currentItem.hallNumber > nextItem.hallNumber) return 1;
        return 0;
      }
    );
    resolve(sortedExpoMapData);
  });
