import { Typography } from "@mui/material";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import GenericLeftNav from "../GenericLayout/GenericLeftNav";
import { Trans, useTranslation } from "react-i18next";
import { SimpleBox } from "../MaterialCustomComponents";

const Exhibitors = () => {
  const { t } = useTranslation();
  return (
    <GenericExpandedMenuWrapper>
      <GenericLeftNav />

      <GenericExpandedMenuContentWrapper>
        <Typography variant="h4" fontWeight="bold">
          {t("exhibitors.main_header")}
        </Typography>
        <Typography variant="subtitle2">
          {t("exhibitors.main_subheader")}
        </Typography>
        <SimpleBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography variant="body1">
            <Trans>exhibitors.main_first_paragraph</Trans>
          </Typography>
          <Typography variant="body1">
            {t("exhibitors.main_second_paragraph")}
          </Typography>
          <Typography variant="body1">
            <Trans>exhibitors.main_third_paragraph</Trans>
          </Typography>
          <Typography variant="body1">
            {t("exhibitors.list_main_header")}
            <ul style={{ paddingLeft: "35px", paddingTop: "15px" }}>
              <Trans components={{ li: <li></li> }}>
                exhibitors.list_items
              </Trans>
            </ul>
          </Typography>
        </SimpleBox>
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

export default Exhibitors;
