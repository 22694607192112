import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import {
  NavigationLinksTypography,
  NavigationLinksWrapper,
  NavigationWrapper,
} from "./NavigationCustomComponents";
import { useRouterStore } from "../../store/useRouterStore";
import {
  RECEPTION,
  EXHIBITION_HALL,
  CONFERENCE_HALL,
  EXHIBITORS_MAP,
  ABOUT,
  EXHIBITORS,
  VISITORS,
  CONTACT,
  NEWSLETTER,
  OFFERS,
  HIGHLIGHT_COLOR,
  BLACK,
} from "../../configs/NAVIGATION_PAGES";
import { MenuItem, Typography, Select, Stack } from "@mui/material";
import { openStripes } from "../../animations/animateStripes";
import { updateRoute } from "../../helpers/updateBoothURL";
import { closeAllNavMenus } from "../../helpers/closeAllNavMenus";
import Router from "next/router";
import { useEffect, useState } from "react";
import { changeLanguage } from "i18next";
import Link from "next/link";
import LanguageIconSVG from "../GenericLayout/LanguageIconSVG";
import { SimpleBox } from "../MaterialCustomComponents";
import { useWindowSize } from "../../hooks/useWindowSize";
import Image from "next/image";

const NavigationSideNav = () => {
  const { setIsMenuExpanded, setIsBurgerOpen } =
    useComponentLoadingStateStore();
  const { height } = useWindowSize();
  const { activeCategory, setActiveCategory } = useRouterStore();
  const [selectedLanguage, setSelectedLanguage] = useState("gr");

  const handleCategoryClick = (category: string) => {
    if (category === undefined || category === null) return;
    const splitedExpoRoute = Router.route.split("/")[1];
    if (
      (category === RECEPTION && Router.route === "/") ||
      (category === EXHIBITION_HALL && splitedExpoRoute === "expo") ||
      (category === CONFERENCE_HALL && Router.route === "/conference_hall")
    ) {
      closeAllNavMenus();
      return;
    }

    switch (category) {
      case RECEPTION:
        openStripes(() => {
          updateRoute("/");
        });
        break;
      case EXHIBITION_HALL:
        openStripes(() => {
          updateRoute("/expo");
        });
        break;
      case CONFERENCE_HALL:
        openStripes(() => {
          updateRoute("/conference_hall");
        });
        break;
      default:
        setIsMenuExpanded(true);
        setActiveCategory(category);
        break;
    }
  };

  useEffect(() => {
    setIsBurgerOpen(true);
    return () => {
      const splitedRoute = Router.route.split("/")[1];
      switch (splitedRoute) {
        case "":
          setActiveCategory(RECEPTION);
          break;
        case "expo":
          setActiveCategory(EXHIBITION_HALL);
          break;
        case "conference_hall":
          setActiveCategory(CONFERENCE_HALL);
          break;
      }
      setIsBurgerOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLanguage = (e: any) => {
    setSelectedLanguage(e.target.value);
    changeLanguage(e.target.value);
  };

  return (
    <SimpleBox>
      <NavigationWrapper
        style={{
          pointerEvents: "auto",
          height: height,
        }}
        sx={{
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
          },
        }}
      >
        <Stack spacing={3}>
          <SimpleBox
            sx={{
              display: "flex",
              gap: "3rem",
            }}
          >
            <Typography color="gray">Go to</Typography>
          </SimpleBox>
          <NavigationLinksWrapper
            onClick={() => handleCategoryClick(RECEPTION)}
          >
            <Link
              href="/"
              style={{
                textDecoration: "none",
                color: activeCategory === RECEPTION ? HIGHLIGHT_COLOR : BLACK,
              }}
              onClick={(e) => e.preventDefault()}
            >
              <NavigationLinksTypography
                variant="h6"
                color={activeCategory === RECEPTION ? HIGHLIGHT_COLOR : BLACK}
              >
                RECEPTION
              </NavigationLinksTypography>
            </Link>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper
            onClick={() => handleCategoryClick(EXHIBITION_HALL)}
          >
            <Link
              href="/expo"
              style={{
                textDecoration: "none",
                color:
                  activeCategory === EXHIBITION_HALL ? HIGHLIGHT_COLOR : BLACK,
              }}
              onClick={(e) => e.preventDefault()}
            >
              <NavigationLinksTypography
                variant="h6"
                color={
                  activeCategory === EXHIBITION_HALL ? HIGHLIGHT_COLOR : BLACK
                }
              >
                EXHIBITION HALL
              </NavigationLinksTypography>
            </Link>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper
            onClick={() => handleCategoryClick(CONFERENCE_HALL)}
          >
            <Link
              href="/conference_hall"
              style={{
                textDecoration: "none",
                color:
                  activeCategory === CONFERENCE_HALL ? HIGHLIGHT_COLOR : BLACK,
              }}
              onClick={(e) => e.preventDefault()}
            >
              <NavigationLinksTypography
                variant="h6"
                color={
                  activeCategory === CONFERENCE_HALL ? HIGHLIGHT_COLOR : BLACK
                }
              >
                CONFERENCE HALL
              </NavigationLinksTypography>
            </Link>
          </NavigationLinksWrapper>
          <hr></hr>
          <Typography color="gray">View</Typography>
          <NavigationLinksWrapper
            onClick={() => handleCategoryClick(EXHIBITORS_MAP)}
          >
            <NavigationLinksTypography
              variant="h6"
              color={
                activeCategory === EXHIBITORS_MAP ? HIGHLIGHT_COLOR : BLACK
              }
            >
              EXHIBITION MAP
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper onClick={() => handleCategoryClick(ABOUT)}>
            <NavigationLinksTypography
              variant="h6"
              color={activeCategory === ABOUT ? HIGHLIGHT_COLOR : BLACK}
            >
              ABOUT
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper onClick={() => handleCategoryClick(VISITORS)}>
            <NavigationLinksTypography
              variant="h6"
              color={activeCategory === VISITORS ? HIGHLIGHT_COLOR : BLACK}
            >
              VISITORS
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper
            onClick={() => handleCategoryClick(EXHIBITORS)}
          >
            <NavigationLinksTypography
              variant="h6"
              color={activeCategory === EXHIBITORS ? HIGHLIGHT_COLOR : BLACK}
            >
              EXHIBITORS
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper onClick={() => handleCategoryClick(OFFERS)}>
            <NavigationLinksTypography
              variant="h6"
              color={activeCategory === OFFERS ? HIGHLIGHT_COLOR : BLACK}
            >
              OFFERS
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper
            onClick={() => handleCategoryClick(NEWSLETTER)}
          >
            <NavigationLinksTypography
              variant="h6"
              color={activeCategory === NEWSLETTER ? HIGHLIGHT_COLOR : BLACK}
            >
              NEWSLETTER
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <NavigationLinksWrapper onClick={() => handleCategoryClick(CONTACT)}>
            <NavigationLinksTypography
              variant="h6"
              color={activeCategory === CONTACT ? HIGHLIGHT_COLOR : BLACK}
            >
              CONTACT
            </NavigationLinksTypography>
          </NavigationLinksWrapper>
          <SimpleBox
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LanguageIconSVG />
            <Select
              value={selectedLanguage}
              onChange={(e) => handleChangeLanguage(e)}
              sx={{
                color: "black",
                height: "20px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.MuiOutlinedInput-root 	.MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              <MenuItem value="en">
                <Image
                  src="/english.png"
                  height={25}
                  width={25}
                  alt="engish language"
                />
              </MenuItem>
              <MenuItem value="gr">
                <Image
                  src="/greek.png"
                  height={25}
                  width={25}
                  alt="greek language"
                />
              </MenuItem>
            </Select>
          </SimpleBox>
        </Stack>
      </NavigationWrapper>
    </SimpleBox>
  );
};

export default NavigationSideNav;
