import { useEffect } from "react";
import { NAVIGATION_PAGES } from "../../configs/NAVIGATION_PAGES";
import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { useRouterStore } from "../../store/useRouterStore";
import { SimpleBox } from "../MaterialCustomComponents";

const pages = Object.keys(NAVIGATION_PAGES);

const ExpandedNavigation = () => {
  const { activeCategory } = useRouterStore();
  const { setIsMenuExpandedMounted } = useComponentLoadingStateStore();
  useEffect(() => {
    setIsMenuExpandedMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SimpleBox
      style={{
        pointerEvents: "auto",
      }}
    >
      {pages.map((route: string) => {
        if (route === activeCategory) {
          return <div key={route}>{NAVIGATION_PAGES[route]}</div>;
        }
      })}
    </SimpleBox>
  );
};

export default ExpandedNavigation;
