import { Button, TextField, styled } from "@mui/material";

export const CustomInput = styled(TextField)(({ theme }) => ({
  borderRadius: "5px",
  backgroundColor: "white",
  width: "25rem",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100vw - 2rem)",
  },
}));

export const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "max-content",
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100vw - 2rem)",
  },
  "&:disabled": {
    color: "#666 !important",
  },
}));
