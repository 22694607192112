import { Skeleton } from "@mui/material";
import { useWindowSize } from "../../hooks/useWindowSize";
import { SimpleBox } from "../MaterialCustomComponents";
import { ImageLogoWrapper } from "./ExhibitorsMapCustomComponents";

const ExhibitorsMapSkeletonImages = ({
  displaySkeleton,
}: {
  displaySkeleton: boolean;
}) => {
  const { width } = useWindowSize();
  return (
    <>
      {width <= 600 && (
        <SimpleBox
          sx={{
            position: "absolute",
            zIndex: displaySkeleton ? -1 : 1,
            top: 0,
            left: 0,
            width: "calc(100vw - 2rem)",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            opacity: displaySkeleton ? 0 : 1,
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
            (num) => (
              <ImageLogoWrapper key={num}>
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={60}
                ></Skeleton>
              </ImageLogoWrapper>
            )
          )}
        </SimpleBox>
      )}{" "}
      {width > 600 && (
        <SimpleBox
          sx={{
            position: "absolute",
            zIndex: displaySkeleton ? -1 : 1,
            top: 0,
            left: 0,
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            height: "calc(100vh - 15rem)",
            alignContent: "start",
            width: "100%",
            opacity: displaySkeleton ? 0 : 1,
          }}
        >
          <SimpleBox
            sx={{
              gridRow: 1,
              gridColumn: "1 / span 12",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SimpleBox>
              <ImageLogoWrapper>
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={80}
                ></Skeleton>
              </ImageLogoWrapper>
            </SimpleBox>
            <SimpleBox>
              <ImageLogoWrapper>
                <Skeleton
                  variant="rectangular"
                  width={150}
                  height={80}
                ></Skeleton>
              </ImageLogoWrapper>
            </SimpleBox>
          </SimpleBox>
          <SimpleBox
            sx={{
              gridRow: 2,
            }}
          >
            {[1, 2, 3, 4, 5].map((num) => {
              return (
                <ImageLogoWrapper key={num}>
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={80}
                  ></Skeleton>
                </ImageLogoWrapper>
              );
            })}
          </SimpleBox>
          <SimpleBox
            sx={{
              gridRow: 2,
            }}
          >
            {[1, 2, 3, 4].map((num) => {
              return (
                <ImageLogoWrapper key={num}>
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={80}
                  ></Skeleton>
                </ImageLogoWrapper>
              );
            })}
          </SimpleBox>
          <SimpleBox
            sx={{
              gridRow: 2,
            }}
          >
            {[1, 2, 3, 4, 5].map((num) => {
              return (
                <ImageLogoWrapper key={num}>
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={80}
                  ></Skeleton>
                </ImageLogoWrapper>
              );
            })}
          </SimpleBox>
        </SimpleBox>
      )}
    </>
  );
};

export default ExhibitorsMapSkeletonImages;
