import { IHalls } from "./interfaces/IHalls";

export const HALLS: IHalls = {
  "1": "H1",
  "2": "H2",
  "3": "H3",
  "4": "H4",
  "5": "H5",
  "6": "H6",
};

export const HALL_ID = "hall-ID";

export const HALL_DUMMY_BOOTHS = [
  {
    position: [80, 0, 25],
    rotation: [0, -Math.PI / 2.8, 0],
  },
  {
    position: [80, 0, 0],
    rotation: [0, -Math.PI / 2.8, 0],
  },
  {
    position: [80, 0, -25],
    rotation: [0, -Math.PI / 2.8, 0],
  },
  {
    position: [80, 0, -50],
    rotation: [0, -Math.PI / 2.8, 0],
  },
  {
    position: [-80, 0, 25],
    rotation: [0, -Math.PI / 1.8, 0],
  },
  {
    position: [-80, 0, 0],
    rotation: [0, -Math.PI / 1.8, 0],
  },
  {
    position: [-80, 0, -25],
    rotation: [0, -Math.PI / 1.8, 0],
  },
  {
    position: [-80, 0, -50],
    rotation: [0, -Math.PI / 1.8, 0],
  },
];
