import gsap from "gsap";

export const animateShowBlackBanner = (cb?: () => void) => {
  gsap.to(".black-bg-banner", {
    opacity: 1,
    duration: 0.3,
    onComplete: () => {
      if (!cb) return;
      cb();
    },
  });
};

export const animateHideBlackBanner = () => {
  gsap.to(".black-bg-banner", {
    opacity: 0,
    duration: 0.3,
  });
};
