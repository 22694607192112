import moment from "moment";
import { getActiveRoom, getLiveVideos } from "./getData";
const getLiveData = async () => {
  const activeRoom = await getActiveRoom();

  const liveVideos = await getLiveVideos(activeRoom);

  if (!liveVideos?.length) return {};
  const liveNow = liveVideos.filter((live) => live.goLive)[0];
  if (liveNow) return liveNow;

  const lastLive = liveVideos.filter((live: any) => {
    return moment().diff(live.date, "days") <= 0;
  })[0];
  return lastLive;
};
export default getLiveData;
