import { IComponentLoadingStateStore } from "./interfaces/IComponentLoadingStateStore";
import create from "zustand";

const useComponentLoadingStateStore = create<IComponentLoadingStateStore>(
  (set) => ({
    isSideNavOpen: false,
    isMenuExpanded: false,
    isMenuExpandedMounted: false,
    isModelLoaded: false,
    isInitialRender: true,
    isExpoPageLoaded: false,
    isReceptionPageLoaded: false,
    isConferenceHallPageLoaded: false,
    isBurgerOpen: false,
    isReceptionAnimationFinished: false,
    isMobileWidth: false,
    isVideoClicked: false,
    isExhibitorsMapIconClicked: false,
    isInfoCategoryClicked: "",
    hallZoomValue: 0,
    isBoothVisitPartActive: false,
    isSingleBoothViewButtonPressed: false,
    loaderPercentage: 0,
    isBoothContactFormOpen: false,
    isBoothModelMounted: false,
    isAnimateToBoothFinished: true,
    isOffersBoxClicked: false,
    isCameraScrollZoomEnabled: false,
    areControlPanelButtonsDisabled: false,
    isCameraResetAnimationFinished: false,
    isVideoCategoryClicked: false,
    hasUserSwiped: false,
    setHasUserSwiped: (hasUserSwiped) => set(() => ({ hasUserSwiped })),
    setIsVideoCategoryClicked: (isVideoCategoryClicked) =>
      set(() => ({ isVideoCategoryClicked })),
    setIsCameraResetAnimationFinished: (isCameraResetAnimationFinished) =>
      set(() => ({
        isCameraResetAnimationFinished,
      })),
    isHallTextureReady: false,
    isBrochurePartActive: false,
    isZoomSelected: false,
    setIsZoomSelected: (isZoomSelected) => set(() => ({ isZoomSelected })),
    isSafariBrowser: false,
    setIsSafariBrowser: (isSafariBrowser) => set(() => ({ isSafariBrowser })),
    setIsBrochurePartActive: (isBrochurePartActive) =>
      set(() => ({ isBrochurePartActive })),
    setIsHallTextureReady: (isHallTextureReady) =>
      set(() => ({ isHallTextureReady })),
    setAreControlPanelButtonsDisabled: (areControlPanelButtonsDisabled) =>
      set(() => ({ areControlPanelButtonsDisabled })),
    setIsCameraZoomEnabled: (isCameraScrollZoomEnabled) =>
      set(() => ({ isCameraScrollZoomEnabled })),
    setIsOffersBoxClicked: (isOffersBoxClicked) =>
      set(() => ({ isOffersBoxClicked })),
    setIsAnimateToBoothFinished: (isAnimateToBoothFinished) =>
      set(() => ({ isAnimateToBoothFinished })),
    setIsBoothModelMounted: (isBoothModelMounted) =>
      set(() => ({ isBoothModelMounted })),
    setIsBoothContactFormOpen: (isBoothContactFormOpen) =>
      set(() => ({ isBoothContactFormOpen })),
    setLoaderPercentage: (loaderPercentage) =>
      set(() => ({ loaderPercentage })),
    setIsSingleBoothViewButtonPressed: (isSingleBoothViewButtonPressed) =>
      set(() => ({ isSingleBoothViewButtonPressed })),
    setIsBoothVisitPartActive: (isBoothVisitPartActive) =>
      set(() => ({ isBoothVisitPartActive })),
    setHallZoomValue: (hallZoomValue) => set(() => ({ hallZoomValue })),
    setIsInfoCategoryClicked: (isInfoCategoryClicked) =>
      set(() => ({ isInfoCategoryClicked })),
    setIsExhibitorsMapIconClicked: (isExhibitorsMapIconClicked: boolean) =>
      set(() => ({ isExhibitorsMapIconClicked })),
    conferenceVideoURL: "https://www.youtube.com/embed/E-LT04NwcTo",
    setConferenceVideoURL: (conferenceVideoURL) =>
      set(() => ({ conferenceVideoURL })),
    setIsVideoClicked: (isVideoClicked) => set(() => ({ isVideoClicked })),
    setIsMobileWidth: (isMobileWidth) => set(() => ({ isMobileWidth })),
    setIsReceptionAnimationFinished: (isReceptionAnimationFinished) =>
      set(() => ({ isReceptionAnimationFinished })),
    setIsBurgerOpen: (isBurgerOpen) => set(() => ({ isBurgerOpen })),
    setIsReceptionPageLoaded: (isReceptionPageLoaded) =>
      set(() => ({ isReceptionPageLoaded })),
    setIsConferenceHallPageLoaded: (isConferenceHallPageLoaded) =>
      set(() => ({ isConferenceHallPageLoaded })),
    setIsExpoPageLoaded: (isExpoPageLoaded) =>
      set(() => ({ isExpoPageLoaded })),
    setIsInitialRender: (isInitialRender) => set(() => ({ isInitialRender })),
    setIsModelLoaded: (isModelLoaded) => set(() => ({ isModelLoaded })),
    setIsSideNavOpen: (isSideNavOpen) => set(() => ({ isSideNavOpen })),
    setIsMenuExpandedMounted: (isMenuExpandedMounted) =>
      set(() => ({ isMenuExpandedMounted })),
    setIsMenuExpanded: (isMenuExpanded) => set(() => ({ isMenuExpanded })),
  })
);

export { useComponentLoadingStateStore };
