import { Button, Typography } from "@mui/material";
import {
  GenericExpandedMenuContentWrapper,
  GenericExpandedMenuWrapper,
} from "../GenericLayout/GenericCustomComponents";
import GenericLeftNav from "../GenericLayout/GenericLeftNav";
import Link from "next/link";
import FacebookIconSVG from "../GenericLayout/FacebookIconSVG";
import InstagramIconSVG from "../GenericLayout/InstagramIconSVG";
import YoutubeIconSVG from "../GenericLayout/YoutubeIconSVG";
import { Trans, useTranslation } from "react-i18next";
import { SimpleBox } from "../MaterialCustomComponents";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <GenericExpandedMenuWrapper>
      <GenericLeftNav showInfo={false} />
      <GenericExpandedMenuContentWrapper>
        <Typography variant="h4" fontWeight="bold">
          {t("contact.main_header")}
        </Typography>
        <Typography variant="subtitle2">
          {t("contact.main_subheader")}
        </Typography>
        <SimpleBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}
        >
          <SimpleBox sx={{ listStyle: "none" }}>
            <Trans components={{ li: <li></li> }}>
              contact.main_categories
            </Trans>
          </SimpleBox>
          <SimpleBox
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Link
              href="https://www.facebook.com/buildingcenter.gr"
              target="blank"
            >
              <FacebookIconSVG />
            </Link>
            <Link
              href="https://www.instagram.com/buildingcenter.gr/"
              target="blank"
            >
              <InstagramIconSVG />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UC2S285PD5Y1IEkChwCEqAhQ"
              target="blank"
            >
              <YoutubeIconSVG />
            </Link>
          </SimpleBox>
          <Button
            variant="primary"
            sx={{ width: "100%", maxWidth: "350px", fontSize: "16px" }}
            onClick={() =>
              window.open("https://ktirio.gr/en/building-center-form")
            }
          >
            {t("contact.main_button")}
          </Button>
        </SimpleBox>
      </GenericExpandedMenuContentWrapper>
    </GenericExpandedMenuWrapper>
  );
};

export default Contact;
