import { useComponentLoadingStateStore } from "../../store/useComponentLoadingStateStore";
import { useEffect } from "react";

const ExhibitorsMapFiberComponent = () => {
  const { isExhibitorsMapIconClicked, setIsExhibitorsMapIconClicked } =
    useComponentLoadingStateStore();
  useEffect(() => {
    if (!isExhibitorsMapIconClicked) return;
    setIsExhibitorsMapIconClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExhibitorsMapIconClicked]);
  return <mesh></mesh>;
};

export default ExhibitorsMapFiberComponent;
